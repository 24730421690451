import styled, { css } from 'styled-components';

import { TextField as MuiTextField } from '@material-ui/core';

export const Container = styled(MuiTextField)`
  ${({ hidden }) =>
    hidden &&
    css`
      display: none;
    `}
`;
