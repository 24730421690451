import yup from 'src/libs/yup';
import { operators } from 'src/utils/helpers';

const oneOfOpearators = operators
  .filter((op) => !!op.value)
  .map((op) => op.value);

const DefaultFilterSchema = yup.object().shape({
  created_at: yup
    .object()
    .optional()
    .shape({
      operator: yup
        .string()
        .transform((value) => (value !== '' ? value : null))
        .nullable()
        .oneOf([null, ...oneOfOpearators])
        .requiredWhen(yup.ref('value'), 'Data')
        .label('Operador'),
      value: yup
        .string()
        .requiredWhen(yup.ref('operator'), 'Operador')
        .dateFormat('dd/MM/yyyy')
        .when('operator', {
          is: 'between',
          then: yup.string().label('De'),
          otherwise: yup.string().label('Data'),
        }),
      value_end: yup
        .string()
        .when('operator', {
          is: 'between',
          then: yup.string().required(),
          otherwise: yup.string().optional(),
        })
        .dateFormat('dd/MM/yyyy')
        .label('Até'),
    })
    .label('Data de Criação'),
  updated_at: yup
    .object()
    .optional()
    .shape({
      operator: yup
        .string()
        .requiredWhen(yup.ref('value'), 'Data')
        .transform((value) => (value !== '' ? value : null))
        .nullable()
        .oneOf([null, ...oneOfOpearators])
        .label('Operador'),
      value: yup
        .string()
        .requiredWhen(yup.ref('operator'), 'Operador')
        .when('operator', {
          is: 'between',
          then: yup.string().label('De'),
          otherwise: yup.string().label('Data'),
        }),
      value_end: yup
        .string()
        .when('operator', {
          is: 'between',
          then: yup.string().required(),
          otherwise: yup.string().optional(),
        })
        .dateFormat('dd/MM/yyyy')
        .label('Até'),
    })
    .label('Data de Alteração'),
  created_by: yup
    .mixed()
    .transform((value) => (value !== '' ? value : null))
    .nullable()
    .label('Criado Por'),
  updated_by: yup
    .mixed()
    .transform((value) => (value !== '' ? value : null))
    .nullable()
    .label('Alterado Por'),
  order_by: yup
    .array()
    .optional()
    .of(
      yup.object().shape({
        column: yup
          .string()
          .requiredWhen(yup.ref('direction'), 'Direção')
          .nullable()
          .label('Coluna'),
        direction: yup
          .string()
          .requiredWhen(yup.ref('column'), 'Coluna')
          .nullable()
          .oneOf(['', 'asc', 'desc'])
          .label('Direção'),
      }),
    )
    .label('Ordenar Por'),
  search: yup.string().label('Busca'),
});

export default DefaultFilterSchema;
