import { FC, useRef } from 'react';

import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import { GridColDef } from '@material-ui/data-grid';
import AddIcon from '@material-ui/icons/Add';
import FilterDrawer from 'src/components/FilterDrawer';
import { FilterDrawerRef } from 'src/components/FilterDrawer/interfaces';
import LinkButton from 'src/components/LinkButton';
import Table from 'src/components/Table';
import TableActions from 'src/components/Table/Actions';
import StatusIcon from 'src/components/Table/StatusIcon';
import usePagination from 'src/hooks/usePagination';
import IPartner from 'src/interfaces/models/IPartner';
import masks from 'src/utils/masks';
import PartnerFilterSchema from 'src/validators/Partner/filter.schema';

import Filters from './Filters';
import { Container } from './styles';

const List: FC = () => {
  const filterRef = useRef<FilterDrawerRef>(null);
  const {
    handlePageChange,
    handlePageSizeChange,
    data,
    loading,
    info,
    loadData,
  } = usePagination<IPartner>('beneficiary-admin/partners');

  const columns: GridColDef[] = [
    { field: 'id', headerName: 'ID' },
    {
      field: 'display_name',
      headerName: 'Nome',
      flex: 1,
      renderCell({ row }) {
        return (
          <div>
            <Typography variant="body2">{row.display_name}</Typography>
            <Typography variant="body2">
              {masks.cnpj(row.headquarter.document)}
            </Typography>
          </div>
        );
      },
    },
    { field: 'group', headerName: 'Grupo' },
    {
      field: 'status',
      headerName: 'Status',
      headerAlign: 'center',
      align: 'center',
      renderCell(params) {
        return <StatusIcon status={params.row.status} />;
      },
    },
    {
      field: '',
      disableColumnMenu: true,
      sortable: false,
      align: 'center',
      renderCell({ id, row }) {
        return (
          <TableActions
            resource="Parceira"
            resourceStatus={row.status}
            editLink={`/parceiras/${id}`}
            deleteApiUrl={`/beneficiary-admin/partners/${id}`}
            restoreApiUrl={`/beneficiary-admin/partners/${id}/restore`}
            descriptionPrefix={`A parceira ${row.display_name}`}
            onFinish={loadData}
          />
        );
      },
    },
  ];

  return (
    <Container>
      <FilterDrawer
        ref={filterRef}
        columns={columns.filter((column) => !column.hide && column.field)}
        yupSchema={PartnerFilterSchema}
      >
        <Filters />
      </FilterDrawer>

      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Grid container justify="space-between" alignItems="center">
            <Typography variant="h5">Parceiras Exclusivas</Typography>

            <LinkButton
              to="/parceiras/novo"
              startIcon={<AddIcon />}
              color="primary"
              variant="contained"
              size="small"
            >
              Novo
            </LinkButton>
          </Grid>
        </Grid>

        <Grid item xs={12}>
          <Card>
            <CardContent>
              <Table
                autoHeight
                rows={data}
                page={info.current_page - 1}
                pageSize={info.per_page}
                rowCount={info.total}
                columns={columns}
                loading={loading}
                pagination
                paginationMode="server"
                onPageChange={handlePageChange}
                onPageSizeChange={handlePageSizeChange}
                onFilterClick={filterRef.current?.open}
                canSearch
              />
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    </Container>
  );
};

export default List;
