import { Item } from 'src/components/Form/Select/interfaces';

export const statesOptions: Item[] = [
  { key: 1, label: 'Acre (AC)', value: 'AC' },
  { key: 2, label: 'Alagoas (AL)', value: 'AL' },
  { key: 3, label: 'Amapá (AP)', value: 'AP' },
  { key: 4, label: 'Amazonas (AM)', value: 'AM' },
  { key: 5, label: 'Bahia (BA)', value: 'BA' },
  { key: 6, label: 'Ceará (CE)', value: 'CE' },
  { key: 7, label: 'Distrito Federal (DF)', value: 'DF' },
  { key: 8, label: 'Espírito Santo (ES)', value: 'ES' },
  { key: 9, label: 'Goiás (GO)', value: 'GO' },
  { key: 10, label: 'Maranhão (MA)', value: 'MA' },
  { key: 11, label: 'Mato Grosso (MT)', value: 'MT' },
  { key: 12, label: 'Mato Grosso do Sul (MS)', value: 'MS' },
  { key: 13, label: 'Minas Gerais (MG)', value: 'MG' },
  { key: 14, label: 'Pará (PA)', value: 'PA' },
  { key: 15, label: 'Paraíba (PB)', value: 'PB' },
  { key: 16, label: 'Paraná (PR)', value: 'PR' },
  { key: 17, label: 'Pernambuco (PE)', value: 'PE' },
  { key: 18, label: 'Piauí (PI)', value: 'PI' },
  { key: 19, label: 'Rio de Janeiro (RJ)', value: 'RJ' },
  { key: 20, label: 'Rio Grande do Norte (RN)', value: 'RN' },
  { key: 21, label: 'Rio Grande do Sul (RS)', value: 'RS' },
  { key: 22, label: 'Rondônia (RO)', value: 'RO' },
  { key: 23, label: 'Roraima (RR)', value: 'RR' },
  { key: 24, label: 'Santa Catarina (SC)', value: 'SC' },
  { key: 25, label: 'São Paulo (SP)', value: 'SP' },
  { key: 26, label: 'Sergipe (SE)', value: 'SE' },
  { key: 27, label: 'Tocantins (TO)', value: 'TO' },
];
