import { FC, useState } from 'react';

import Divider from '@material-ui/core/Divider';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import { Scope } from '@unform/core';
import DatePicker from 'src/components/Form/DatePicker';
import Select from 'src/components/Form/Select';
import { operators } from 'src/utils/helpers';

const statusItems = [
  { key: 1, label: 'Ativo', value: 'active' },
  { key: 2, label: 'Inativo', value: 'inactive' },
];

const Filters: FC = () => {
  const [signedBetween, setSignedBetween] = useState(false);
  const [notifyBetween, setNotifyBetween] = useState(false);
  const [expireBetween, setExpireBetween] = useState(false);

  return (
    <>
      <Grid item xs={12}>
        <Divider />
      </Grid>

      <Scope path="signed_at">
        <Grid item xs={12}>
          <Typography variant="body1">Assinado em</Typography>
        </Grid>
        <Grid item xs={4}>
          <Select
            name="operator"
            label="Operador"
            items={operators}
            onChange={(value: any) => setSignedBetween(value === 'between')}
            variant="outlined"
            fullWidth
            size="small"
          />
        </Grid>

        <Grid item xs={signedBetween ? 4 : 8}>
          <DatePicker
            label={signedBetween ? 'De' : 'Data'}
            name="value"
            fullWidth
            defaultValue={null}
            inputVariant="outlined"
            size="small"
          />
        </Grid>

        {signedBetween && (
          <Grid item xs={4}>
            <DatePicker
              label="Até"
              name="value_end"
              fullWidth
              defaultValue={null}
              inputVariant="outlined"
              size="small"
            />
          </Grid>
        )}
      </Scope>

      <Scope path="notify_at">
        <Grid item xs={12}>
          <Typography variant="body1">Notificar em</Typography>
        </Grid>
        <Grid item xs={4}>
          <Select
            name="operator"
            label="Operador"
            items={operators}
            onChange={(value: any) => setNotifyBetween(value === 'between')}
            variant="outlined"
            fullWidth
            size="small"
          />
        </Grid>

        <Grid item xs={notifyBetween ? 4 : 8}>
          <DatePicker
            label={notifyBetween ? 'De' : 'Data'}
            name="value"
            fullWidth
            defaultValue={null}
            inputVariant="outlined"
            size="small"
          />
        </Grid>

        {notifyBetween && (
          <Grid item xs={4}>
            <DatePicker
              label="Até"
              name="value_end"
              fullWidth
              defaultValue={null}
              inputVariant="outlined"
              size="small"
            />
          </Grid>
        )}
      </Scope>

      <Scope path="expire_at">
        <Grid item xs={12}>
          <Typography variant="body1">Expira em</Typography>
        </Grid>
        <Grid item xs={4}>
          <Select
            name="operator"
            label="Operador"
            items={operators}
            onChange={(value: any) => setExpireBetween(value === 'between')}
            variant="outlined"
            fullWidth
            size="small"
          />
        </Grid>

        <Grid item xs={expireBetween ? 4 : 8}>
          <DatePicker
            label={expireBetween ? 'De' : 'Data'}
            name="value"
            fullWidth
            defaultValue={null}
            inputVariant="outlined"
            size="small"
          />
        </Grid>

        {expireBetween && (
          <Grid item xs={4}>
            <DatePicker
              label="Até"
              name="value_end"
              fullWidth
              defaultValue={null}
              inputVariant="outlined"
              size="small"
            />
          </Grid>
        )}
      </Scope>

      <Grid item xs={6}>
        <Select name="status" label="Status" items={statusItems} size="small" />
      </Grid>
    </>
  );
};

export default Filters;
