import { useState } from 'react';
import { useHistory, useLocation } from 'react-router';

import { objectToQuery, queryToObject } from 'src/utils/helpers';

import { IUseFilterReturn } from './interfaces';

function useFilter(): IUseFilterReturn {
  const [filterOpen, setFilterOpen] = useState(false);
  const history = useHistory();
  const location = useLocation();

  const applyFilters = (data: Record<string, any>) => {
    const query = new URLSearchParams(location.search);

    data.page = 1;
    data.per_page = query.get('per_page') || 10;

    const finalParams = {
      ...queryToObject(query),
      ...data,
    };

    history.replace({
      pathname: location.pathname,
      search: objectToQuery(finalParams, { addQueryPrefix: false }),
    });
  };

  return {
    applyFilters,
    filterOpen,
    setFilterOpen,
  };
}

export default useFilter;
