import { FC } from 'react';
import {
  Link as RouterLink,
  LinkProps as RouterLinkProps,
} from 'react-router-dom';

import MuiButton, {
  ButtonProps as MuiButtonProps,
} from '@material-ui/core/Button';

type LinkButtonProps = RouterLinkProps & MuiButtonProps;

const LinkButton: FC<LinkButtonProps> = ({ children, ...rest }) => {
  return (
    <MuiButton component={RouterLink} {...rest}>
      {children}
    </MuiButton>
  );
};

export default LinkButton;
