import { FC, useRef } from 'react';
import { useParams, useRouteMatch } from 'react-router';

import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import { GridColDef } from '@material-ui/data-grid';
import AddIcon from '@material-ui/icons/Add';
import format from 'date-fns/format';
import parseISO from 'date-fns/parseISO';
import FilterDrawer from 'src/components/FilterDrawer';
import { FilterDrawerRef } from 'src/components/FilterDrawer/interfaces';
import LinkButton from 'src/components/LinkButton';
import Table from 'src/components/Table';
import TableActions from 'src/components/Table/Actions';
import { columnPercent } from 'src/components/Table/types';
import usePagination from 'src/hooks/usePagination';
import { IEditPartnerParams } from 'src/interfaces/forms/IPartner';
import { IPartnerService } from 'src/interfaces/models/IPartner';
import PartnerServiceFilterSchema from 'src/validators/PartnerService/filter.schema';

import Filters from './Filters';
import { Container } from './styles';

const List: FC = () => {
  const filterRef = useRef<FilterDrawerRef>(null);
  const params = useParams<IEditPartnerParams>();
  const { url } = useRouteMatch();
  const {
    handlePageChange,
    handlePageSizeChange,
    data,
    loading,
    info,
    loadData,
  } = usePagination<IPartnerService>(
    `beneficiary-admin/partners/${params.id}/services`,
  );

  const columns: GridColDef[] = [
    { field: 'id', headerName: 'ID' },
    {
      field: 'service',
      headerName: 'Serviço',
      flex: 1,
      renderCell({ row }) {
        return (
          <div>
            <Typography variant="body2" title={row.service?.name}>
              {row.service?.name}
            </Typography>
            <Typography variant="body2" title={row.service?.category?.name}>
              {row.service?.category?.name}
            </Typography>
          </div>
        );
      },
    },
    {
      field: 'teach_form',
      headerName: 'Forma de Ensino',
      flex: 0.5,
      valueGetter({ row }) {
        return row.teach_form?.name;
      },
    },
    {
      field: 'discount',
      headerName: 'Desconto',
      headerAlign: 'center',
      align: 'center',
      flex: 1,
      ...columnPercent,
    },
    {
      field: 'updated',
      headerName: 'Atualizado em',
      width: 130,
      align: 'center',
      renderCell({ row }) {
        return (
          <div>
            <Typography variant="body2">
              {format(parseISO(row.updated_at), 'dd/MM/yyyy HH:mm')}
            </Typography>
            <Typography variant="body2">- {row.updated_by} -</Typography>
          </div>
        );
      },
    },
    {
      field: '',
      disableColumnMenu: true,
      sortable: false,
      align: 'right',
      renderCell({ id, row }) {
        return (
          <TableActions
            resource="Serviço"
            resourceStatus={row.status}
            editLink={`${url}/${id}`}
            deleteApiUrl={`/beneficiary-admin/partners/${params.id}/services/${id}`}
            restoreApiUrl={`/beneficiary-admin/partners/${params.id}/services/${id}/restore`}
            descriptionPrefix={`O serviço ${row.service?.name}`}
            onFinish={loadData}
          />
        );
      },
    },
  ];

  return (
    <Container>
      <FilterDrawer
        ref={filterRef}
        columns={columns.filter((column) => !column.hide && column.field)}
        yupSchema={PartnerServiceFilterSchema}
      >
        <Filters />
      </FilterDrawer>

      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Grid container justify="flex-end" alignItems="center">
            <LinkButton
              to={`${url}/novo`}
              startIcon={<AddIcon />}
              color="primary"
              variant="contained"
              size="small"
            >
              Novo
            </LinkButton>
          </Grid>
        </Grid>

        <Grid item xs={12}>
          <Card>
            <CardContent>
              <Table
                autoHeight
                rows={data}
                page={info.current_page - 1}
                pageSize={info.per_page}
                rowCount={info.total}
                columns={columns}
                loading={loading}
                pagination
                paginationMode="server"
                onPageChange={handlePageChange}
                onPageSizeChange={handlePageSizeChange}
                onFilterClick={filterRef.current?.open}
              />
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    </Container>
  );
};

export default List;
