import yup from 'src/libs/yup';

const PartnerFilterSchema = yup.object().shape({
  display_name: yup.string().label('Nome'),
  document: yup.string().label('CNPJ'),
  group: yup.string().label('Grupo'),
  city: yup.string().label('Cidade'),
  state: yup.string().label('UF'),
  solicitation: yup.string().nullable().label('Solicitação do Usuário'),
  status: yup.string().nullable().label('Status'),
});

export default PartnerFilterSchema;
