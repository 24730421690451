import dateFormat from 'date-fns/format';
import localePtBR from 'date-fns/locale/pt-BR';
import parseISO from 'date-fns/parseISO';
import _ from 'lodash';
import QueryString, { ParsedQs, IParseOptions, IStringifyOptions } from 'qs';

export const queryToObject = (
  query: URLSearchParams,
  options?: Partial<IParseOptions & { decoder?: never }>,
): ParsedQs => {
  return QueryString.parse(query.toString(), {
    parseArrays: true,
    plainObjects: true,
    ...options,
  });
};

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const removeEmpty = (param: any): Record<string, any> => {
  function internalClean(el: any) {
    return _.transform(el, (result: any, value, key) => {
      const isCollection = _.isObject(value);
      const cleaned = isCollection ? internalClean(value) : value;

      if ((isCollection && _.isEmpty(cleaned)) || !cleaned) {
        return;
      }

      _.isArray(result) ? result.push(cleaned) : (result[key] = cleaned);
    });
  }

  return _.isObject(param) ? internalClean(param) : param;
};

export const objectToQuery = (
  params: Record<string, any>,
  options?: Partial<IStringifyOptions>,
): string => {
  return QueryString.stringify(removeEmpty(params), {
    arrayFormat: 'indices',
    addQueryPrefix: true,
    ...options,
  });
};

export const operators = [
  { key: 1, label: 'Menor', value: '<' },
  { key: 2, label: 'Menor igual', value: '<=' },
  { key: 3, label: 'Igual', value: '=' },
  { key: 4, label: 'Maior igual', value: '>=' },
  { key: 5, label: 'Maior', value: '>' },
  { key: 6, label: 'Intervalo', value: 'between' },
];

export const orderDirections = [
  { key: 1, label: 'Crescente', value: 'asc' },
  { key: 2, label: 'Decrescente', value: 'desc' },
];

export const objectToFormData = (
  data: Record<string, any>,
  parentKey?: string,
  formData?: FormData,
): FormData => {
  if (!formData) formData = new FormData();

  if (
    data &&
    typeof data === 'object' &&
    !(data instanceof Date) &&
    !(data instanceof File)
  ) {
    Object.keys(data).forEach((key) => {
      objectToFormData(
        data[key],
        parentKey ? `${parentKey}[${key}]` : key,
        formData,
      );
    });
  } else {
    if (parentKey && data !== undefined) {
      if (data instanceof Date) {
        formData.append(parentKey, data.toJSON());
      } else {
        formData.append(parentKey, data === null ? '' : data);
      }
    }
  }

  return formData;
};

export const formatDate = (date: string, format: string): string => {
  return dateFormat(parseISO(date), format, { locale: localePtBR });
};
