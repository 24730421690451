import { FC, useCallback, useContext, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router';

import {
  ClickAwayListener,
  ListItemIcon,
  ListItemText,
  MenuItem,
  MenuList,
} from '@material-ui/core';
import Button from '@material-ui/core/Button';
import Grow from '@material-ui/core/Grow';
import Paper from '@material-ui/core/Paper';
import Popper from '@material-ui/core/Popper';
import AccountCircleIcon from '@material-ui/icons/AccountCircle';
import PowerIcon from '@material-ui/icons/PowerSettingsNew';
import PrivateContext from 'src/routes/Private/PrivateContext';
import api from 'src/services/api';
import { AuthActions } from 'src/store/ducks/auth';
import { handleApiResponseErrors } from 'src/utils/errors';

// import { Container } from './styles';

const UserMenu: FC = () => {
  const userMenuRef = useRef<HTMLButtonElement>(null);
  const [isOpen, setIsOpen] = useState(false);
  const user = useSelector((state) => state.auth.user);
  const dispatch = useDispatch();
  const history = useHistory();
  const { startLayoutLoading, stopLayoutLoading } = useContext(PrivateContext);

  const handleClick = useCallback(() => setIsOpen((value) => !value), []);

  const handleLogout = async () => {
    try {
      startLayoutLoading();
      setIsOpen(false);

      await api.delete('beneficiary-admin/logout');

      dispatch(AuthActions.authLogout());
      history.push('/');
    } catch (error) {
      handleApiResponseErrors(error.response);
    } finally {
      stopLayoutLoading();
    }
  };

  return (
    <>
      <Button
        endIcon={<AccountCircleIcon />}
        ref={userMenuRef}
        color="inherit"
        onClick={handleClick}
      >
        {user?.name}
      </Button>

      <Popper
        open={isOpen}
        anchorEl={userMenuRef.current}
        transition
        disablePortal
      >
        {({ TransitionProps, placement }) => (
          <Grow
            {...TransitionProps}
            style={{
              transformOrigin:
                placement === 'bottom' ? 'center top' : 'center bottom',
            }}
          >
            <Paper>
              <ClickAwayListener onClickAway={() => setIsOpen(false)}>
                <MenuList>
                  <MenuItem button onClick={handleLogout}>
                    <ListItemIcon>
                      <PowerIcon />
                    </ListItemIcon>
                    <ListItemText primary="Sair" />
                  </MenuItem>
                </MenuList>
              </ClickAwayListener>
            </Paper>
          </Grow>
        )}
      </Popper>
    </>
  );
};

export default UserMenu;
