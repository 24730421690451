import { createContext } from 'react';

interface Private {
  sideMenuIsOpen: boolean;
  openSideMenu(): void;
  closeSideMenu(): void;
  layoutLoading: boolean;
  startLayoutLoading(): void;
  stopLayoutLoading(): void;
}

const PrivateContext = createContext<Private>({
  sideMenuIsOpen: false,
  openSideMenu: () => null,
  closeSideMenu: () => null,
  layoutLoading: false,
  startLayoutLoading: () => null,
  stopLayoutLoading: () => null,
});

export default PrivateContext;
