import {
  ForwardRefRenderFunction,
  forwardRef,
  useCallback,
  useRef,
  useState,
  useImperativeHandle,
  useEffect,
} from 'react';
import { useLocation } from 'react-router';

import { Typography } from '@material-ui/core';
import Grid from '@material-ui/core/Grid';
import CheckIcon from '@material-ui/icons/Check';
import CloseIcon from '@material-ui/icons/Close';
import { FormHandles, Scope } from '@unform/core';
import { Form } from '@unform/web';
import Button from 'src/components/Button';
import DatePicker from 'src/components/Form/DatePicker';
import Select from 'src/components/Form/Select';
import { Item } from 'src/components/Form/Select/interfaces';
import useFilter from 'src/hooks/useFilter';
import { IUser } from 'src/interfaces/models';
import api from 'src/services/api';
import { handleApiResponseErrors, showFormErrors } from 'src/utils/errors';
import {
  operators,
  orderDirections,
  queryToObject,
  objectToQuery,
} from 'src/utils/helpers';
import yupValidate from 'src/utils/yupValidate';
import DefaultFilterSchema from 'src/validators/DefaultFilter.schema';

import { FilterDrawerProps, FilterDrawerRef } from './interfaces';
import { Drawer } from './styles';

const FilterDrawer: ForwardRefRenderFunction<
  FilterDrawerRef,
  FilterDrawerProps
> = ({ children, disableDefaultFilters, columns, yupSchema, ...rest }, ref) => {
  const formRef = useRef<FormHandles>(null);
  const [disabled, setDisabled] = useState(false);
  const [createdBetween, setCreatedBetween] = useState(false);
  const [updatedBetween, setUpdatedBetween] = useState(false);
  const [userItems, setUserItems] = useState<Item[]>([]);
  const { filterOpen, setFilterOpen, applyFilters } = useFilter();
  const location = useLocation();

  const query = new URLSearchParams(location.search);
  const formData = queryToObject(query);

  useImperativeHandle(ref, () => ({
    open: () => setFilterOpen(true),
    close: () => setFilterOpen(false),
    getFormRef: () => formRef,
  }));

  useEffect(() => {
    const loadData = async () => {
      try {
        const params = objectToQuery({
          order_by: [{ column: 'name', direction: 'asc' }],
        });
        const response = await api.get(`/admin/users${params}`);

        const users = response.data as IUser[];

        setUserItems(
          users.map((user) => ({
            key: user.id,
            label: user.name,
            value: user.id,
          })),
        );
      } catch (error) {}
    };

    loadData();
  }, []);

  const handleSubmit = useCallback(
    async (formData) => {
      try {
        setDisabled(true);
        formRef.current?.setErrors({});

        let filterData = {};

        if (!disableDefaultFilters) {
          const { success, data, errors } = await yupValidate(
            DefaultFilterSchema,
            formData,
          );

          if (!success) {
            return showFormErrors(errors, formRef);
          }

          filterData = data;
        }

        if (yupSchema) {
          const { success, data, errors } = await yupValidate(
            yupSchema,
            formData,
          );

          if (!success) {
            return showFormErrors(errors, formRef);
          }

          filterData = { ...filterData, ...data };
        }

        applyFilters(filterData);
        setFilterOpen(false);
      } catch (error) {
        handleApiResponseErrors(error.response, 'Erro ao aplicar filtros.');
      } finally {
        setDisabled(false);
      }
    },
    [disableDefaultFilters, yupSchema, applyFilters, setFilterOpen],
  );

  return (
    <Drawer
      open={filterOpen}
      onOpen={() => setFilterOpen(true)}
      onClose={() => setFilterOpen(false)}
      anchor="right"
      {...rest}
    >
      <Typography color="primary" variant="h5" align="center">
        Filtros
      </Typography>

      <Form ref={formRef} onSubmit={handleSubmit} initialData={formData}>
        <Grid container spacing={1}>
          {!disableDefaultFilters && (
            <>
              <Scope path="created_at">
                <Grid item xs={12}>
                  <Typography variant="body1">Data de Criação</Typography>
                </Grid>
                <Grid item xs={4}>
                  <Select
                    name="operator"
                    label="Operador"
                    items={operators}
                    onChange={(value: any) =>
                      setCreatedBetween(value === 'between')
                    }
                    variant="outlined"
                    fullWidth
                    size="small"
                  />
                </Grid>

                <Grid item xs={createdBetween ? 4 : 8}>
                  <DatePicker
                    label={createdBetween ? 'De' : 'Data'}
                    name="value"
                    fullWidth
                    defaultValue={null}
                    inputVariant="outlined"
                    size="small"
                  />
                </Grid>

                {createdBetween && (
                  <Grid item xs={4}>
                    <DatePicker
                      label="Até"
                      name="value_end"
                      fullWidth
                      defaultValue={null}
                      inputVariant="outlined"
                      size="small"
                    />
                  </Grid>
                )}
              </Scope>

              <Scope path="updated_at">
                <Grid item xs={12}>
                  <Typography variant="body1">Data de Alteração</Typography>
                </Grid>
                <Grid item xs={4}>
                  <Select
                    name="operator"
                    label="Operador"
                    items={operators}
                    onChange={(value: any) =>
                      setUpdatedBetween(value === 'between')
                    }
                    variant="outlined"
                    fullWidth
                    size="small"
                  />
                </Grid>

                <Grid item xs={updatedBetween ? 4 : 8}>
                  <DatePicker
                    label={updatedBetween ? 'Até' : 'Data'}
                    name="value"
                    fullWidth
                    defaultValue={null}
                    inputVariant="outlined"
                    size="small"
                  />
                </Grid>

                {updatedBetween && (
                  <Grid item xs={4}>
                    <DatePicker
                      label="Até"
                      name="value_end"
                      fullWidth
                      defaultValue={null}
                      inputVariant="outlined"
                      size="small"
                    />
                  </Grid>
                )}
              </Scope>

              <Grid item xs={12} sm={6}>
                <Select
                  name="created_by"
                  label="Criado Por"
                  items={userItems}
                  variant="outlined"
                  fullWidth
                  size="small"
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <Select
                  name="updated_by"
                  label="Alterado Por"
                  items={userItems}
                  variant="outlined"
                  fullWidth
                  size="small"
                />
              </Grid>

              {columns && (
                <Scope path="order_by[0]">
                  <Grid item xs={8}>
                    <Select
                      name="column"
                      label="Ordenar por Coluna"
                      items={columns.map((column) => ({
                        key: column.field,
                        label: column.headerName || '',
                        value: column.field,
                      }))}
                      variant="outlined"
                      fullWidth
                      size="small"
                    />
                  </Grid>

                  <Grid item xs={4}>
                    <Select
                      name="direction"
                      label="Direção"
                      items={orderDirections}
                      variant="outlined"
                      fullWidth
                      size="small"
                    />
                  </Grid>
                </Scope>
              )}
            </>
          )}

          {children}

          <Grid item xs={12}>
            <Grid container justify="space-between">
              <Button
                type="reset"
                disabled={disabled}
                variant="contained"
                color="secondary"
                startIcon={<CloseIcon />}
                onClick={() => formRef.current?.setData({})}
              >
                Limpar
              </Button>

              <Button
                type="submit"
                disabled={disabled}
                variant="contained"
                color="primary"
                startIcon={<CheckIcon />}
              >
                Aplicar
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </Form>
    </Drawer>
  );
};

export default forwardRef(FilterDrawer);
