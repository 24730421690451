import React, { ReactNode, useRef, useState } from 'react';
import {
  Link as RouterLink,
  LinkProps as RouterLinkProps,
} from 'react-router-dom';
import { toast } from 'react-toastify';

import Divider from '@material-ui/core/Divider';
import IconButton from '@material-ui/core/IconButton';
import Menu from '@material-ui/core/Menu';
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import RestoreIcon from '@material-ui/icons/Restore';
import ConfirmDialog from 'src/components/ConfirmDialog';
import { ConfirmDialogRef } from 'src/components/ConfirmDialog/interfaces';
import CustomMenuItem from 'src/components/Table/CustomMenuItem';
import api from 'src/services/api';
import { handleApiResponseErrors } from 'src/utils/errors';

interface TableActionsProps {
  resource: string;
  resourceStatus?: string;
  startItems?: ReactNode;
  middleItems?: ReactNode;
  endItems?: ReactNode;
  editLink?: RouterLinkProps['to'];
  deleteApiUrl?: string;
  restoreApiUrl?: string;
  restoreText?: string;
  restoreStatuses?: string[];
  descriptionPrefix?: string;
  onFinish?: () => void;
}

const TableActions: React.FC<TableActionsProps> = ({
  resource,
  resourceStatus,
  startItems,
  middleItems,
  endItems,
  editLink,
  deleteApiUrl,
  restoreApiUrl,
  restoreText,
  restoreStatuses,
  descriptionPrefix,
  onFinish,
}) => {
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement>();
  const open = Boolean(anchorEl);
  const deleteDialogRef = useRef<ConfirmDialogRef>(null);
  const restoreDialogRef = useRef<ConfirmDialogRef>(null);
  const [dialogLoading, setDialogLoading] = useState(false);

  const handleClose = () => {
    setAnchorEl(undefined);
  };

  const handleOpenDeleteDialog = () => {
    deleteDialogRef.current?.show();
  };

  const handleConfirmDelete = async () => {
    try {
      if (!deleteApiUrl) return;

      setDialogLoading(true);

      const isDelete = deleteDialogRef.current?.isDelete();
      let deleteConfig = {};
      if (isDelete) {
        deleteConfig = { data: { delete: true } };
      }

      await api.delete(deleteApiUrl, deleteConfig);

      toast.success(`${resource} ${isDelete ? 'excluido' : 'desativado'}(a)!`);

      deleteDialogRef.current?.hide();

      if (onFinish) onFinish();
    } catch (error) {
      handleApiResponseErrors(
        error.response,
        `Erro ao desativar ${resource.toLowerCase()}.`,
      );
    } finally {
      setDialogLoading(false);
    }
  };

  const handleOpenRestoreDialog = () => {
    restoreDialogRef.current?.show();
  };

  const handleConfirmRestore = async () => {
    try {
      if (!restoreApiUrl) return;
      setDialogLoading(true);

      await api.post(restoreApiUrl);
      toast.success(`${resource} restaurado(a)!`);

      restoreDialogRef.current?.hide();
      if (onFinish) onFinish();
    } catch (error) {
      handleApiResponseErrors(
        error.response,
        `Erro ao restaurar ${resource.toLowerCase()}.`,
      );
    } finally {
      setDialogLoading(false);
    }
  };

  return (
    <div>
      <IconButton size="medium" onClick={(e) => setAnchorEl(e.currentTarget)}>
        <MoreVertIcon fontSize="inherit" />
      </IconButton>

      <Menu
        anchorEl={anchorEl}
        keepMounted
        open={open}
        onClose={handleClose}
        onClick={handleClose}
      >
        {startItems && (
          <>
            {startItems}

            <Divider />
          </>
        )}

        {editLink && (
          <CustomMenuItem
            button
            component={(props: any) => <RouterLink {...props} to={editLink} />}
            text="Editar"
            Icon={EditIcon}
            iconProps={{ color: 'primary' }}
          />
        )}

        {middleItems}

        <Divider />

        {deleteApiUrl && (
          <CustomMenuItem
            text="Excluir"
            Icon={DeleteIcon}
            iconProps={{ color: 'secondary' }}
            onClick={handleOpenDeleteDialog}
          />
        )}

        {restoreApiUrl && restoreStatuses?.includes(resourceStatus || '') && (
          <CustomMenuItem
            text={restoreText || 'Restaurar'}
            Icon={RestoreIcon}
            iconProps={{ color: 'primary' }}
            onClick={handleOpenRestoreDialog}
          />
        )}

        {endItems && (
          <>
            <Divider />

            {endItems}
          </>
        )}
      </Menu>

      <ConfirmDialog
        ref={deleteDialogRef}
        title={`Desativar ${resource}`}
        description={`${descriptionPrefix} será desativado(a). Confirma esta ação?`}
        confirmColor={'secondary'}
        onConfirm={handleConfirmDelete}
        loading={dialogLoading}
        canDelete
      />

      <ConfirmDialog
        ref={restoreDialogRef}
        title={`Restaurar ${resource}`}
        description={`${descriptionPrefix} será restaurado(a). Confirma esta ação?`}
        confirmColor="primary"
        onConfirm={handleConfirmRestore}
        loading={dialogLoading}
      />
    </div>
  );
};

TableActions.defaultProps = {
  descriptionPrefix: 'O Registro',
  restoreText: 'Restaurar',
  restoreStatuses: ['inactive'],
};

export default TableActions;
