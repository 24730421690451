import { FC } from 'react';


import { Container } from './styles';

const NotFound: FC = () => {
  return (
    <Container>
      <h1>NotFound</h1>
    </Container>
  );
};

export default NotFound;
