import yup from 'src/libs/yup';

export const PartnerResponsibleStoreSchema = yup.object().shape({
  name: yup.string().required().label('Nome'),
  document: yup.string().label('CPF'),
  email: yup.string().label('E-mail'),
  phone: yup.string().label('Telefone'),
  department: yup.string().label('Departamento'),
  legal: yup.boolean(),
  partnership: yup.boolean(),
  contacts: yup.array().of(
    yup.object().shape({
      id: yup.string(),
      contact_type: yup.string().required().label('Tipo'),
      contact_value: yup.string().required().label('Contato'),
    }),
  ),
});

export const PartnerResponsibleUpdateSchema = yup.object().shape({
  name: yup.string().required().label('Nome'),
  document: yup.string().label('CPF'),
  email: yup.string().label('E-mail'),
  phone: yup.string().label('Telefone'),
  department: yup.string().label('Departamento'),
  legal: yup.boolean(),
  partnership: yup.boolean(),
  contacts: yup.array().of(
    yup.object().shape({
      id: yup.string(),
      contact_type: yup.string().required().label('Tipo'),
      contact_value: yup.string().required().label('Contato'),
    }),
  ),
});
