import styled, { css } from 'styled-components';

import Button from 'src/components/Button';

const defaultStyles = css`
  width: 100%;
  margin-bottom: ${({ theme }) => theme.spacing(1)}px;
`;

export const PreviewImg = styled.img`
  ${defaultStyles}

  max-height: 200px;
  object-fit: contain;
`;

export const PreviewFile = styled(Button).attrs(({}) => {
  return {
    component: 'a',
    target: '_blank',
  };
})`
  ${defaultStyles}
`;
