import yup from 'src/libs/yup';

interface YupValidadeResponse {
  success: boolean;
  data: any;
  errors: Record<string, string>;
}

const yupValidate = async (
  schema: yup.BaseSchema,
  data = {},
  options = {},
): Promise<YupValidadeResponse> => {
  const formErrors: Record<string, string> = {};
  let success = false;
  let validData = {};

  try {
    const result = await schema.validate(data, {
      abortEarly: false,
      stripUnknown: true,
      ...options,
    });

    success = true;
    validData = result;
  } catch (e) {
    success = false;

    if (yup.ValidationError.isError(e)) {
      e.inner.forEach((error) => {
        if (error.path) {
          let label = '';
          try {
            label = yup.reach(schema, error.path).describe().label || '';
          } catch (error) {}
          formErrors[error.path] = error.message.replace(error.path, label);
        }
      });
    }
  }

  return {
    success,
    data: validData,
    errors: formErrors,
  };
};

export default yupValidate;
