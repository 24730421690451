import { FC } from 'react';

// import Navigation from 'src/components/Navigation';

import { Container, Content } from './styles';

const PublicLayout: FC = ({ children }) => {
  return (
    <Container>
      {/* <Navigation /> */}
      <Content>{children}</Content>
    </Container>
  );
};

export default PublicLayout;
