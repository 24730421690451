import { createTheme } from '@material-ui/core';
import { ptBR } from '@material-ui/data-grid';

export default createTheme(
  {
    palette: {
      type: 'light',
    },
  },
  ptBR,
);
