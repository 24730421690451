import yup from 'src/libs/yup';

export const PartnerServiceStoreSchema = yup.object().shape({
  service_id: yup.string().required().label('Serviço'),
  teach_form_id: yup.string().nullable().label('Forma de Ensino'),
  discount: yup.string().required().label('Desconto'),
  file: yup.mixed().isFile().label('Arquivo'),
  message: yup.string().label('Mensagem'),
  custom_voucher_enabled: yup.bool(),
  custom_voucher_content: yup.string().label('Conteúdo do Voucher'),
  custom_voucher_qrcode: yup.string().label('Conteúdo do QRcode'),
  default_voucher_attached: yup.bool(),
});

export const PartnerServiceUpdateSchema = yup.object().shape({
  service_id: yup.string().required().label('Serviço'),
  teach_form_id: yup.string().nullable().label('Forma de Ensino'),
  discount: yup.string().required().label('Desconto'),
  file: yup.mixed().isFile().label('Arquivo'),
  message: yup.string().label('Mensagem'),
  custom_voucher_enabled: yup.bool(),
  custom_voucher_content: yup.string().nullable().label('Conteúdo do Voucher'),
  custom_voucher_qrcode: yup.string().nullable().label('Conteúdo do QRcode'),
  default_voucher_attached: yup.bool(),
});
