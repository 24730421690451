import { DataGridProps } from '@material-ui/data-grid';

export interface IPaginationInfo {
  total: number;
  per_page: number;
  current_page: number;
  last_page: number;
  first_page: number;
  first_page_url?: string;
  last_page_url?: string;
  next_page_url?: string;
  previous_page_url?: string;
}

export interface IUsePaginationReturn<T> {
  handlePageChange: DataGridProps['onPageChange'];
  handlePageSizeChange: DataGridProps['onPageSizeChange'];
  loading: boolean;
  info: IPaginationInfo;
  data: T[];
  loadData(): void;
}

export const INITIAL_INFO: IPaginationInfo = {
  current_page: 1,
  first_page: 1,
  last_page: 1,
  per_page: 10,
  total: 0,
};
