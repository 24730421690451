import { FC } from 'react';
import { useHistory } from 'react-router-dom';

import IconButton from '@material-ui/core/IconButton';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';

const BackButton: FC = () => {
  const history = useHistory();

  return (
    <IconButton size="small" onClick={() => history.goBack()}>
      <ArrowBackIcon />
    </IconButton>
  );
};

export default BackButton;
