import isValid from 'date-fns/isValid';
import parseDate from 'date-fns/parse';
import { StringSchema } from 'yup';

export function validateDateFormat(
  this: StringSchema,
  format: string,
  msg?: string,
): any {
  return this.test({
    name: 'dateFormat',
    exclusive: false,
    message: msg || '${path} não possui um formato válido',
    test(value) {
      if (!value) {
        return true;
      }

      return isValid(parseDate(value || '', format, new Date()));
    },
  });
}
