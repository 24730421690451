import styled, { css } from 'styled-components';

import MuiLinearProgess from '@material-ui/core/LinearProgress';

export const TOP_NAVIGATION_HEIGHT = 64; // Valor em px
export const SIDE_NAVIGATION_WIDTH = 240; // Valor em px

export const Container = styled.div`
  display: flex;
`;

export const Content = styled.main<{ isOpen?: boolean }>`
  flex-grow: 1;
  height: 100%;
  overflow: auto;
  margin-left: -${SIDE_NAVIGATION_WIDTH}px;
  transition: ${({ theme }) =>
    theme.transitions.create('margin', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    })};

  ${({ isOpen, theme }) =>
    isOpen &&
    css`
      transition: ${theme.transitions.create('margin', {
        easing: theme.transitions.easing.easeOut,
        duration: theme.transitions.duration.enteringScreen,
      })};
      margin-left: 0;
    `}
`;

export const AppBarSpacer = styled.div`
  min-height: 56px;
  position: relative;

  ${({ theme }) => css`
    ${theme.breakpoints.up('sm')} {
      min-height: 64px;
    }
  `}
`;

export const LoadingProgress = styled(MuiLinearProgess)`
  position: absolute;
  bottom: -4px;
  width: 100%;
`;
