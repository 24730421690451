import yup from 'src/libs/yup';
import { operators } from 'src/utils/helpers';

const oneOfOpearators = operators
  .filter((op) => !!op.value)
  .map((op) => op.value);

const PartnerContractFilterSchema = yup.object().shape({
  signed_at: yup
    .object()
    .optional()
    .shape({
      operator: yup
        .string()
        .transform((value) => (value !== '' ? value : null))
        .nullable()
        .oneOf([null, ...oneOfOpearators])
        .requiredWhen(yup.ref('value'), 'Data')
        .label('Operador'),
      value: yup
        .string()
        .requiredWhen(yup.ref('operator'), 'Operador')
        .dateFormat('dd/MM/yyyy')
        .when('operator', {
          is: 'between',
          then: yup.string().label('De'),
          otherwise: yup.string().label('Data'),
        }),
      value_end: yup
        .string()
        .when('operator', {
          is: 'between',
          then: yup.string().required(),
          otherwise: yup.string().optional(),
        })
        .dateFormat('dd/MM/yyyy')
        .label('Até'),
    })
    .label('Assinado Em'),
  notify_at: yup
    .object()
    .optional()
    .shape({
      operator: yup
        .string()
        .transform((value) => (value !== '' ? value : null))
        .nullable()
        .oneOf([null, ...oneOfOpearators])
        .requiredWhen(yup.ref('value'), 'Data')
        .label('Operador'),
      value: yup
        .string()
        .requiredWhen(yup.ref('operator'), 'Operador')
        .dateFormat('dd/MM/yyyy')
        .when('operator', {
          is: 'between',
          then: yup.string().label('De'),
          otherwise: yup.string().label('Data'),
        }),
      value_end: yup
        .string()
        .when('operator', {
          is: 'between',
          then: yup.string().required(),
          otherwise: yup.string().optional(),
        })
        .dateFormat('dd/MM/yyyy')
        .label('Até'),
    })
    .label('Notificar Em'),
  expire_at: yup
    .object()
    .optional()
    .shape({
      operator: yup
        .string()
        .transform((value) => (value !== '' ? value : null))
        .nullable()
        .oneOf([null, ...oneOfOpearators])
        .requiredWhen(yup.ref('value'), 'Data')
        .label('Operador'),
      value: yup
        .string()
        .requiredWhen(yup.ref('operator'), 'Operador')
        .dateFormat('dd/MM/yyyy')
        .when('operator', {
          is: 'between',
          then: yup.string().label('De'),
          otherwise: yup.string().label('Data'),
        }),
      value_end: yup
        .string()
        .when('operator', {
          is: 'between',
          then: yup.string().required(),
          otherwise: yup.string().optional(),
        })
        .dateFormat('dd/MM/yyyy')
        .label('Até'),
    })
    .label('Expira Em'),
  status: yup.string().nullable().label('Status'),
});

export default PartnerContractFilterSchema;
