import { FC, useRef, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { toast } from 'react-toastify';

import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import { SubmitHandler, FormHandles } from '@unform/core';
import TextField from 'src/components/Form/TextField';
import Link from 'src/components/Link';
import api from 'src/services/api';
import { handleApiResponseErrors, showFormErrors } from 'src/utils/errors';
import yupValidate from 'src/utils/yupValidate';
import forgotMyPasswordSchema from 'src/validators/User/forgotMyPassword.schema';

import { Content, Form, SubmitButton } from './styles';

const ForgotPassword: FC = () => {
  const formRef = useRef<FormHandles>(null);
  const [loading, setLoading] = useState(false);

  const history = useHistory();

  const handleOnSubmit: SubmitHandler = async (formData) => {
    try {
      setLoading(true);
      formRef.current?.setErrors({});

      const { success, data, errors } = await yupValidate(
        forgotMyPasswordSchema,
        formData,
      );

      if (!success) {
        return showFormErrors(errors, formRef);
      }

      await api.post('/beneficiary-admin/forgot-password', data);

      toast('Verifique seu email para recuperar sua senha.', {
        type: 'success',
      });

      history.push('/');
    } catch (error) {
      handleApiResponseErrors(
        error.response,
        'Erro',
        forgotMyPasswordSchema,
        formRef,
      );
    } finally {
      setLoading(false);
    }
  };

  return (
    <Container component="main" maxWidth="xs">
      <Content>
        <Card>
          <CardContent>
            <Typography component="h1" variant="h5" align="center">
              Esqueci Minha Senha
            </Typography>
            <Form ref={formRef} onSubmit={handleOnSubmit}>
              <TextField
                name="email"
                label="E-mail"
                fullWidth
                variant="outlined"
              />

              <SubmitButton
                loading={loading}
                type="submit"
                fullWidth
                variant="contained"
                color="primary"
              >
                Recuperar Senha
              </SubmitButton>
            </Form>

            <Grid container>
              <Link to="/" variant="body2">
                Voltar ao Login
              </Link>
            </Grid>
          </CardContent>
        </Card>
      </Content>
    </Container>
  );
};

export default ForgotPassword;
