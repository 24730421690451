import { FC, useCallback, useEffect, useRef, useState } from 'react';

import MenuItem from '@material-ui/core/MenuItem';
import MuiTextField from '@material-ui/core/TextField';
import { useField } from '@unform/core';

import { InputRef, SelectProps } from './interfaces';

const Select: FC<SelectProps> = ({ name, items, onChange, label, ...rest }) => {
  const selectRef = useRef<InputRef>(null);

  // defaultValue
  const {
    fieldName,
    registerField,
    error,
    defaultValue = '',
    clearError,
  } = useField(name);

  const [selectedValue, setSelectedValue] = useState(defaultValue ?? '');

  useEffect(() => {
    if (selectRef.current)
      registerField<any>({
        name: fieldName,
        ref: selectRef,
        getValue: (ref) => {
          return ref.current.value;
        },
        setValue: (_, value) => {
          setSelectedValue(value ?? '');
          clearError();
        },
        clearValue: (_, newValue) => {
          setSelectedValue(newValue ?? '');
          clearError();
        },
      });
  }, [fieldName, registerField, clearError]);

  useEffect(() => {
    if (defaultValue && items.length > 0) {
      setSelectedValue(defaultValue);
    }
  }, [defaultValue, items]);

  useEffect(() => {
    if (selectRef.current) {
      selectRef.current.value = selectedValue;

      if (onChange) onChange(selectedValue);
    }
  }, [selectRef, selectedValue, onChange]);

  const handleChange = useCallback(
    (e) => {
      e.persist();

      const { value } = e.target;

      clearError();

      setSelectedValue(value);
    },
    [clearError],
  );

  return (
    <MuiTextField
      select
      label={label}
      inputRef={selectRef}
      name={fieldName}
      onChange={handleChange}
      value={selectedValue}
      error={!!error}
      helperText={error}
      variant="outlined"
      fullWidth
      {...rest}
    >
      <MenuItem value="">-- Remover --</MenuItem>
      {items.map((item) => (
        <MenuItem key={item.key} value={item.value}>
          {item.label}
        </MenuItem>
      ))}
    </MuiTextField>
  );
};

export default Select;
