import { FC } from 'react';

import {
  GridToolbarColumnsButton,
  GridToolbarDensitySelector,
  GridToolbarContainer,
} from '@material-ui/data-grid';
import FilterListIcon from '@material-ui/icons/FilterList';
import Button from 'src/components/Button';

import { CustomToolbarProps } from './interfaces';

const CustomToolbar: FC<CustomToolbarProps> = ({ onFilterClick, ...rest }) => {
  return (
    <GridToolbarContainer {...rest}>
      <GridToolbarColumnsButton />
      <GridToolbarDensitySelector />

      {onFilterClick && (
        <Button
          startIcon={<FilterListIcon />}
          onClick={onFilterClick}
          color="primary"
          size="small"
        >
          Filtros
        </Button>
      )}
    </GridToolbarContainer>
  );
};

export default CustomToolbar;
