import { createStore } from 'redux';
import { persistStore, persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';

// import createSagaMiddleware from 'redux-saga';

import rootReducer from './ducks/rootReducer';
// import rootSaga from './ducks/rootSaga';

const persistConfig = {
  key: 'ifepaf-admin-app',
  storage,
  whitelist: ['auth'],
};

const persistedReducer = persistReducer(persistConfig, rootReducer);

// const sagaMiddleware = createSagaMiddleware();

// const composer = compose(applyMiddleware(sagaMiddleware));

const store = createStore(persistedReducer);
const persistor = persistStore(store);

// sagaMiddleware.run(rootSaga);

export { store, persistor };
