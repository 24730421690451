import React from 'react';
import { Link as RouterLink, useLocation } from 'react-router-dom';

import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemCollapse from 'src/components/ListItemCollapse';
import { ISideMenuItem } from 'src/interfaces/ISideMenu';

// import { Container } from './styles';

interface IItemProps {
  item: ISideMenuItem;
}

const Item: React.FC<IItemProps> = ({ item }) => {
  const { pathname } = useLocation();

  function isItemSelected(currentItem: ISideMenuItem): boolean {
    if (currentItem.subItems) {
      return currentItem.subItems.some((subItem) => isItemSelected(subItem));
    } else if (currentItem.route) {
      return pathname === currentItem.route;
    }

    return false;
  }

  if (item.subItems) {
    return (
      <ListItemCollapse
        primary={item.primaryText}
        icon={item.icon && <item.icon />}
        collapseProps={{
          in: item.subItems?.some((subItem) => isItemSelected(subItem)),
        }}
      >
        {item.subItems.map((subItem) => (
          <Item key={subItem.primaryText} item={subItem} />
        ))}
      </ListItemCollapse>
    );
  }

  return (
    <ListItem
      key={item.route}
      button
      component={(props) => (
        <RouterLink
          {...props}
          to={{ pathname: item.route, search: item.search }}
        />
      )}
      selected={isItemSelected(item)}
    >
      {item.icon && (
        <ListItemIcon>
          <item.icon />
        </ListItemIcon>
      )}
      <ListItemText primary={item.primaryText} secondary={item.secondaryText} />
    </ListItem>
  );
};

export default Item;
