import {
  ForwardRefRenderFunction,
  useState,
  useImperativeHandle,
  forwardRef,
  useCallback,
} from 'react';

import { TextField } from '@material-ui/core';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import CheckIcon from '@material-ui/icons/Check';
import Button from 'src/components/Button';

import { ConfirmDialogRef, ConfirmDialogProps } from './interfaces';

const ConfirmDialog: ForwardRefRenderFunction<
  ConfirmDialogRef,
  ConfirmDialogProps
> = (
  {
    title,
    description,
    loading,
    confirmVariant = 'contained',
    confirmColor = 'secondary',
    onConfirm,
    canDelete,
    ...rest
  },
  ref,
) => {
  const [open, setOpen] = useState(false);
  const [makeDelete, setMakeDelete] = useState(false);

  const handleClose = useCallback(() => {
    setOpen(false);
    setMakeDelete(false);
  }, []);

  useImperativeHandle(ref, () => ({
    show: () => setOpen(true),
    hide: () => handleClose(),
    isDelete: () => makeDelete,
  }));

  return (
    <Dialog open={open} onClose={handleClose} fullWidth maxWidth="xs" {...rest}>
      <DialogTitle>{title}</DialogTitle>

      <DialogContent>
        {description && (
          <DialogContentText variant="body1">{description}</DialogContentText>
        )}

        {canDelete && (
          <>
            <DialogContentText variant="body1" color="error">
              <div>Para apenas Desativar, clique no botão DESATIVAR.</div>
              <div>
                <strong>Essa ação pode ser revertida.</strong>
              </div>
              <br />
              <div>
                Para excluir definitivamente o cadastro, preencha o campo abaixo
                e clique no botão EXCLUIR.
              </div>
              <div>
                <strong>Essa ação é irreversível.</strong>
              </div>
            </DialogContentText>

            <TextField
              variant="outlined"
              color="secondary"
              fullWidth
              placeholder="Digite 'Excluir' sem aspas"
              size="small"
              onChange={(e) => setMakeDelete(e.target.value === 'Excluir')}
            />

            {makeDelete && (
              <DialogContentText variant="body1" color="error">
                <i>
                  Informações vinculadas a esse registro também serão excluidas,{' '}
                  <strong>e não podem ser revertidas!</strong>
                </i>
              </DialogContentText>
            )}
          </>
        )}
      </DialogContent>

      <DialogActions>
        <Button
          startIcon={<CheckIcon />}
          loading={loading}
          color={confirmColor}
          variant={confirmVariant}
          onClick={onConfirm}
        >
          {!canDelete ? 'Confirmar' : makeDelete ? 'Excluir' : 'Desativar'}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default forwardRef(ConfirmDialog);
