import { FC, useCallback, useEffect, useRef, useState } from 'react';

import CircularProgress from '@material-ui/core/CircularProgress';
import TextField from '@material-ui/core/TextField';
import MuiAutocomplete from '@material-ui/lab/Autocomplete';
import { useField } from '@unform/core';

import { AutocompleteProps, AutocompleteRef, IOption } from './interfaces';

const Autocomplete: FC<AutocompleteProps> = ({
  name,
  options,
  label,
  onChange,
  loading,
  textFieldProps,
  ...rest
}) => {
  const autocompleteRef = useRef<AutocompleteRef>(null);
  const [value, setValue] = useState<IOption[] | IOption | null>(
    rest.multiple ? ([] as IOption[]) : null,
  );

  const {
    fieldName,
    registerField,
    error,
    defaultValue,
    clearError,
  } = useField(name);

  const handleSetValue = useCallback(
    (newValue) => {
      if (rest.multiple) {
        if (Array.isArray(newValue)) {
          const selectedValues = autocompleteRef.current?.options?.filter(
            (option) =>
              newValue.find((val) => val == option.value) !== undefined,
          );
          setValue(selectedValues || []);
        } else {
          setValue([]);
        }
      } else {
        if (typeof newValue === 'object') {
          setValue(newValue);
        } else {
          const selectedValue = autocompleteRef.current?.options?.find(
            (option) => option.value == newValue,
          );
          setValue(selectedValue || null);
        }
      }
    },
    [rest.multiple],
  );

  useEffect(() => {
    registerField<any>({
      name: fieldName,
      ref: autocompleteRef,
      getValue: (ref) => {
        if (Array.isArray(ref.current?.value)) {
          return ref.current.value.map((option: IOption) => option.value);
        } else {
          return ref.current.value?.value ?? ''; // 1º value is from ref, 2º is from selected option
        }
      },
      setValue: (_, newValue) => {
        handleSetValue(newValue);
        clearError();
      },
      clearValue: (_, newValue) => {
        handleSetValue(newValue);
        clearError();
      },
    });
  }, [fieldName, registerField, clearError, handleSetValue]);

  useEffect(() => {
    if (defaultValue) {
      handleSetValue(defaultValue);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [defaultValue]);

  useEffect(() => {
    if (autocompleteRef.current) {
      autocompleteRef.current.value = value;
    }

    if (onChange) onChange(value);
  }, [value, onChange]);

  useEffect(() => {
    if (autocompleteRef.current) {
      autocompleteRef.current.options = options;
    }
  }, [options]);

  const handleChange = useCallback(
    (e, newOption) => {
      e.persist();

      clearError();

      setValue(newOption);
    },
    [clearError],
  );

  return (
    <MuiAutocomplete
      ref={autocompleteRef}
      value={value}
      onChange={handleChange}
      getOptionSelected={(option, value) => option.value == value.value}
      getOptionLabel={(option) => option.label}
      options={options}
      loading={loading}
      renderInput={(params) => (
        <TextField
          {...params}
          label={label}
          type="text"
          name={name}
          error={!!error}
          helperText={error}
          inputProps={{
            ...params.inputProps,
            autoComplete: 'off',
          }}
          InputProps={{
            ...params.InputProps,
            endAdornment: (
              <>
                {loading ? (
                  <CircularProgress color="inherit" size={20} />
                ) : null}
                {params.InputProps.endAdornment}
              </>
            ),
          }}
          variant="outlined"
          {...textFieldProps}
        />
      )}
      {...rest}
    />
  );
};

export default Autocomplete;
