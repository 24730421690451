import LabelOutlinedIcon from '@material-ui/icons/LabelOutlined';
import { ISideMenuItem } from 'src/interfaces/ISideMenu';

const MenuItems: ISideMenuItem[] = [
  {
    primaryText: 'Parceiras Exclusivas',
    route: '/parceiras',
    icon: LabelOutlinedIcon,
  },
];

export default MenuItems;
