import { FC, useContext, useEffect, useRef, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';

import { CardHeader } from '@material-ui/core';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import CheckIcon from '@material-ui/icons/Check';
import CloseIcon from '@material-ui/icons/Delete';
import { SubmitHandler, FormHandles } from '@unform/core';
import { Form } from '@unform/web';
import Button from 'src/components/Button';
import ConfirmDialog from 'src/components/ConfirmDialog';
import { ConfirmDialogRef } from 'src/components/ConfirmDialog/interfaces';
import Autocomplete from 'src/components/Form/Autocomplete';
import {
  AutocompleteProps,
  IOption,
} from 'src/components/Form/Autocomplete/interfaces';
import CheckBox from 'src/components/Form/Checkbox';
import InputFile from 'src/components/Form/InputFile';
import RichText from 'src/components/Form/RichText';
import TextField from 'src/components/Form/TextField';
import { IEditPartnerServiceParams } from 'src/interfaces/forms/IPartner';
import { IService, ITeachForm } from 'src/interfaces/models';
import { IPartnerService } from 'src/interfaces/models/IPartner';
import PrivateContext from 'src/routes/Private/PrivateContext';
import api from 'src/services/api';
import { handleApiResponseErrors, showFormErrors } from 'src/utils/errors';
import { objectToFormData, objectToQuery } from 'src/utils/helpers';
import yupValidate from 'src/utils/yupValidate';
import {
  PartnerServiceStoreSchema,
  PartnerServiceUpdateSchema,
} from 'src/validators/PartnerService/save.schema';

import { Container } from './styles';

const Save: FC = () => {
  const { startLayoutLoading, stopLayoutLoading } = useContext(PrivateContext);
  const formRef = useRef<FormHandles>(null);
  const confirmDialogRef = useRef<ConfirmDialogRef>(null);
  const [loading, setLoading] = useState(false);
  const [services, setServices] = useState<IService[]>([]);
  const [teachForms, setTeachForms] = useState<ITeachForm[]>([]);
  const [disableTeachForm, setDisableTeachForm] = useState(false);
  const history = useHistory();
  const params = useParams<IEditPartnerServiceParams>();

  const yupSchema = params.serviceId
    ? PartnerServiceUpdateSchema
    : PartnerServiceStoreSchema;

  useEffect(() => {
    const loadData = async () => {
      try {
        startLayoutLoading();

        const query = objectToQuery({
          order_by: [{ column: 'name', direction: 'asc' }],
          load_category: true,
          status: 'active',
          slug: 'serviços',
        });
        const getServices = api.get<IService[]>(
          `/beneficiary-admin/services${query}` /** Terminar isso */,
        );
        const getTeachForms = api.get(
          `/beneficiary-admin/teach-forms${query}`,
        ); /** Esse também */

        const [servicesResp, teachFormsResp] = await Promise.all([
          getServices,
          getTeachForms,
        ]);
        setServices(servicesResp.data);
        setTeachForms(teachFormsResp.data);

        if (params.serviceId) {
          const response = await api.get<IPartnerService>(
            `beneficiary-admin/partners/${params.id}/services/${params.serviceId}`,
          );
          const partnerService = response.data;

          formRef.current?.setData(partnerService);
        }
      } catch (error) {
        handleApiResponseErrors(error.response, 'Erro ao buscar dados.');
      } finally {
        stopLayoutLoading();
      }
    };

    loadData();
  }, [params.id, params.serviceId, startLayoutLoading, stopLayoutLoading]);

  const handleOnSubmit: SubmitHandler = async (unformData) => {
    try {
      setLoading(true);
      formRef.current?.setErrors({});

      const { success, data, errors } = await yupValidate(
        yupSchema,
        unformData,
      );

      if (!success) {
        return showFormErrors(errors, formRef);
      }

      const formData = objectToFormData(data);

      if (params.serviceId) {
        await api.put(
          `/beneficiary-admin/partners/${params.id}/services/${params.serviceId}`,
          formData,
        );
      } else {
        await api.post(
          `/beneficiary-admin/partners/${params.id}/services`,
          formData,
        );
      }

      toast.success('Dados salvos com sucesso!');
      history.goBack();
    } catch (error) {
      handleApiResponseErrors(error.response, 'Erro', yupSchema, formRef);
    } finally {
      setLoading(false);
    }
  };

  const handleDelete = async () => {
    try {
      setLoading(true);

      await api.delete(
        `/beneficiary-admin/partners/${params.id}/services/${params.serviceId}`,
      );
      toast.success('Serviço removido!');
      history.goBack();
    } catch (error) {
      handleApiResponseErrors(error.response, 'Erro ao atualizar serviço.');
    } finally {
      setLoading(false);
    }
  };

  const onChangeService: AutocompleteProps['onChange'] = (
    value: IOption | null,
  ) => {
    const disable =
      services.find((service) => service.id === value?.value)?.category?.benefit
        ?.type === 'bonus';

    setDisableTeachForm(disable);

    if (disable) {
      formRef.current?.setFieldValue('teach_form_id', null);
    }
  };

  return (
    <Container maxWidth="md">
      <Form ref={formRef} onSubmit={handleOnSubmit} noValidate>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Typography variant="h6">
              {params.serviceId ? 'Editar' : 'Novo'} Serviço
            </Typography>
          </Grid>

          <Grid item xs={12}>
            <Card>
              <CardContent>
                <Grid container spacing={1}>
                  <Grid item xs={12} md={6}>
                    <Autocomplete
                      name="service_id"
                      label="Serviço"
                      textFieldProps={{ required: true }}
                      options={services.map((service) => ({
                        key: service.id,
                        label: `${service.name} (${service.category?.name})`,
                        value: service.id,
                      }))}
                      onChange={onChangeService}
                    />
                  </Grid>

                  <Grid item xs={12} sm={6} md={3}>
                    <Autocomplete
                      name="teach_form_id"
                      label="Forma de Ensino"
                      options={teachForms.map((teachForm) => ({
                        key: teachForm.id,
                        label: teachForm.name,
                        value: teachForm.id,
                      }))}
                      disabled={disableTeachForm}
                    />
                  </Grid>

                  <Grid item xs={12} sm={6} md={3}>
                    <TextField
                      label="Desconto (%)"
                      name="discount"
                      mask="percent"
                      returnUnmasked
                      required
                    />
                  </Grid>

                  <Grid item xs={12} sm={6} md={3}>
                    <InputFile
                      name="file"
                      label="Arquivo"
                      previewAlt="File"
                      canDelete
                      // accept="application/pdf"
                    />
                  </Grid>
                </Grid>
              </CardContent>
            </Card>
          </Grid>

          <Grid item xs={12}>
            <Card>
              <CardContent>
                <Grid container spacing={1}>
                  <Grid item xs={12}>
                    <RichText name="message" />
                  </Grid>
                </Grid>
              </CardContent>
            </Card>
          </Grid>

          <Grid item xs={12}>
            <Card>
              <CardHeader subheader="Voucher Personalizado" />

              <CardContent>
                <Grid container spacing={1}>
                  <Grid item xs={12}>
                    <CheckBox
                      name="custom_voucher_enabled"
                      label="Exibir voucher customizado"
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <CheckBox
                      name="default_voucher_attached"
                      label="Incluir voucher padrão ao customizado"
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <RichText name="custom_voucher_content">
                      Conteúdo do Voucher
                    </RichText>
                  </Grid>
                  <Grid item xs={12}>
                    <TextField
                      name="custom_voucher_qrcode"
                      label="Conteúdo do QRcode"
                    />
                  </Grid>
                </Grid>
              </CardContent>
            </Card>
          </Grid>

          <Grid item xs={12}>
            <Grid container justify="space-between">
              <Button
                type="button"
                loading={loading}
                startIcon={<CloseIcon />}
                variant="contained"
                color="secondary"
                disabled={!params.serviceId}
                onClick={() => confirmDialogRef.current?.show()}
              >
                Remover
              </Button>

              <Button
                type="submit"
                loading={loading}
                startIcon={<CheckIcon />}
                variant="contained"
                color="primary"
              >
                Salvar
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </Form>

      <ConfirmDialog
        ref={confirmDialogRef}
        title="Remover Serviço"
        description="Confirma a exclusão? Ela não pode ser desfeita."
        confirmColor="secondary"
        onConfirm={handleDelete}
      />
    </Container>
  );
};

export default Save;
