import styled from 'styled-components';

import MuiSwipeableDrawer from '@material-ui/core/SwipeableDrawer';
import { SIDE_NAVIGATION_WIDTH } from 'src/routes/Private/Layout/styles';

export const Drawer = styled(MuiSwipeableDrawer)`
  > .MuiDrawer-paper {
    padding: ${({ theme }) => theme.spacing(1)}px;
    width: 80%;
    max-width: 500px;
    min-width: ${SIDE_NAVIGATION_WIDTH}px;
  }
`;
