import { FC, useCallback, useState } from 'react';

import MuiListItem from '@material-ui/core/ListItem';
import MuiListItemIcon from '@material-ui/core/ListItemIcon';
import MuiListItemText from '@material-ui/core/ListItemText';
import ExpandLessIcon from '@material-ui/icons/ExpandLess';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

import { ListItemCollapseProps } from './interfaces';
import { Collapse } from './styles';

const ListItemCollapse: FC<ListItemCollapseProps> = ({
  children,
  primary,
  secondary,
  icon,
  collapseProps,
}) => {
  const [isOpen, setIsOpen] = useState(collapseProps?.in || false);

  const handleClick = useCallback(() => setIsOpen((value) => !value), []);

  return (
    <>
      <MuiListItem button onClick={handleClick} selected={isOpen}>
        {icon && <MuiListItemIcon>{icon}</MuiListItemIcon>}

        <MuiListItemText primary={primary} secondary={secondary} />

        {isOpen ? <ExpandLessIcon /> : <ExpandMoreIcon />}
      </MuiListItem>
      <Collapse unmountOnExit timeout="auto" {...collapseProps} in={isOpen}>
        {children}
      </Collapse>
    </>
  );
};

export default ListItemCollapse;
