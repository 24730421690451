import { RefObject } from 'react';
import { toast } from 'react-toastify';

import { FormHandles } from '@unform/core';
import { AxiosResponse } from 'axios';
import yup from 'src/libs/yup';
import { store } from 'src/store';
import { AuthActions } from 'src/store/ducks/auth';

interface APIValidationError {
  rule: string;
  field: string;
  message: string;
}

export const showErrorToast = (message: string): void => {
  toast(message, { type: 'error' });
};

export const showFormErrors = (
  errors: Record<string, string>,
  formRef: RefObject<FormHandles>,
): void => {
  showErrorToast('Por favor, verifique os dados informados.');

  formRef?.current?.setErrors(errors);
};

export const handleApiValidationErrors = (
  errors: APIValidationError[],
  yupSchema: yup.BaseSchema,
  formRef: RefObject<FormHandles>,
): void => {
  const formErrors: Record<string, string> = {};

  errors.forEach((error) => {
    let label = '';
    try {
      label = yup.reach(yupSchema, error.field).describe().label || '';
    } catch (error) {}

    formErrors[error.field] = error.message.replace(error.field, label);
  });

  showFormErrors(formErrors, formRef);
};

export const handleApiResponseErrors = (
  apiResponse: AxiosResponse,
  defaultMessage = 'Ocorreu algum erro.',
  yupSchema?: yup.BaseSchema,
  formRef?: RefObject<FormHandles>,
): void => {
  if (!apiResponse) {
    return showErrorToast(defaultMessage);
  }

  switch (apiResponse.status) {
    case 400:
    case 404:
      return showErrorToast(apiResponse.data.custom_error || defaultMessage);
    case 401:
      if (apiResponse.data.logout) {
        store.dispatch(AuthActions.authLogout());
      }

      return showErrorToast(apiResponse.data.custom_error || defaultMessage);
    case 422:
      if (!yupSchema || !formRef) {
        return showErrorToast(defaultMessage);
      } else {
        return handleApiValidationErrors(
          apiResponse.data.errors,
          yupSchema,
          formRef,
        );
      }
    default:
      if (apiResponse.data.custom_error) {
        return showErrorToast(apiResponse.data.custom_error);
      } else if (Array.isArray(apiResponse.data.errors)) {
        const errorMessage = apiResponse.data.errors
          .map((error: any) => error.message)
          .join(' ');

        return showErrorToast(errorMessage);
      } else {
        return showErrorToast(defaultMessage);
      }
  }
};
