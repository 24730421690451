import { FC, useCallback, useEffect, useRef, useState } from 'react';

import DateFnsUtils from '@date-io/date-fns';
import {
  KeyboardDatePicker as MuiDatePicker,
  MuiPickersUtilsProvider,
} from '@material-ui/pickers';
import { MaterialUiPickersDate } from '@material-ui/pickers/typings/date';
import { useField } from '@unform/core';
import isValid from 'date-fns/isValid';
import ptBR from 'date-fns/locale/pt-BR';
import parseDATE from 'date-fns/parse';
import parseISO from 'date-fns/parseISO';

import { DatePickerType, InputRef } from './interfaces';

const DatePicker: FC<DatePickerType> = ({
  name,
  disableText,
  inputProps,
  onChange,
  valueAsDate,
  ...rest
}) => {
  const inputRef = useRef<InputRef>({ value: '', rawValue: null });

  const {
    defaultValue,
    error,
    fieldName,
    registerField,
    clearError,
  } = useField(name);

  const [date, setDate] = useState<MaterialUiPickersDate>(null);

  useEffect(() => {
    registerField<string>({
      name: fieldName,
      ref: inputRef,
      getValue: (ref) => {
        return valueAsDate ? ref.current?.rawValue : ref.current?.value;
      },
      setValue: (_, value) => {
        const parsed = parseISO(value);
        setDate(isValid(parsed) ? parsed : null);
        clearError();
      },
      clearValue: (_, newValue) => {
        const parsed = parseISO(newValue);
        setDate(isValid(parsed) ? parsed : null);
        clearError();
      },
    });
  }, [fieldName, registerField, onChange, clearError, valueAsDate]);

  // Default value
  useEffect(() => {
    if (typeof defaultValue === 'string') {
      const value = parseDATE(defaultValue, 'dd/MM/yyyy', new Date(), {
        locale: ptBR,
      });
      setDate(isValid(value) ? value : null);
    } else if (defaultValue instanceof Date) {
      setDate(defaultValue);
    } else {
      setDate(null);
    }
  }, [defaultValue]);

  // date change
  useEffect(() => {
    inputRef.current.rawValue = date;

    if (onChange) onChange(date);
  }, [date, onChange, clearError]);

  // On change
  const handleChange = useCallback(
    (value: MaterialUiPickersDate) => {
      clearError();
      setDate(value);
    },
    [clearError],
  );

  return (
    <MuiPickersUtilsProvider utils={DateFnsUtils} locale={ptBR}>
      <MuiDatePicker
        inputRef={inputRef}
        disableToolbar
        error={!!error}
        helperText={error}
        format="dd/MM/yyyy"
        placeholder="dd/mm/aaaa"
        invalidDateMessage="Fomato inválido"
        maxDateMessage="Data não deve ser maior que a máxima."
        minDateMessage="Data não deve ser menor que a mínima."
        inputProps={{
          ...inputProps,
          disabled: disableText,
        }}
        onChange={handleChange}
        value={date}
        defaultValue={defaultValue}
        variant="dialog"
        clearLabel="Limpar"
        cancelLabel="Cancelar"
        clearable
        inputVariant="outlined"
        fullWidth
        {...rest}
      />
    </MuiPickersUtilsProvider>
  );
};

export default DatePicker;
