import styled from 'styled-components';

import MuiAvatar from '@material-ui/core/Avatar';
import Grid from '@material-ui/core/Grid';
import MuiPaper from '@material-ui/core/Paper';
import { Form as Unform } from '@unform/web';
import Button from 'src/components/Button';

export const Container = styled(Grid)`
  height: 100vh;
`;

export const GridImage = styled(Grid)`
  background-image: url('https://source.unsplash.com/random');
  background-repeat: no-repeat;
  background-color: ${({ theme }) =>
    theme.palette.type === 'light'
      ? theme.palette.grey[50]
      : theme.palette.grey[900]};
  background-size: cover;
  background-position: center;
`;

export const GridForm = styled(Grid)``;

export const Paper = styled(MuiPaper)`
  padding: ${({ theme }) => theme.spacing(8, 4)};
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const Avatar = styled(MuiAvatar)`
  margin: ${({ theme }) => theme.spacing(1, 0)};
  background-color: ${({ theme }) => theme.palette.secondary.main};
`;

export const Form = styled(Unform)`
  width: 100%;
`;

export const SubmitButton = styled(Button)`
  margin: ${({ theme }) => theme.spacing(3, 0, 2)};
`;
