import { FC } from 'react';

import {
  CheckIcon,
  CloseIcon,
  HelpOutlineIcon,
  PendingIcon,
  RejectedIcon,
  NotifiedIcon,
} from './styles';

interface StatusIconProps {
  status: string;
}

const StatusIcon: FC<StatusIconProps> = ({ status }) => {
  switch (status) {
    case 'active':
      return <CheckIcon titleAccess="Ativo" />;
    case 'inactive':
      return <CloseIcon titleAccess="Desativado" />;
    case 'pending':
      return <PendingIcon titleAccess="Pendente" />;
    case 'rejected':
      return <RejectedIcon titleAccess="Negado" />;
    case 'notified':
      return <NotifiedIcon titleAccess="Notificado" />;
    default:
      return <HelpOutlineIcon />;
  }
};

export default StatusIcon;
