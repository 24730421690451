import { FC } from 'react';
import { BrowserRouter, Switch } from 'react-router-dom';

import { IRouteInterface } from 'src/interfaces/IRoutes';

import AuthRoute from './Auth';
import PrivateRoute from './Private';
import PublicRoute from './Public';
import routes from './routes-list';

const { REACT_APP_TITLE_PREFIX } = process.env;

const Routes: FC = () => {
  const makeTitle = (title?: string, label?: string): string => {
    const pageTitleSuffix = title || label || '';
    const pageTitle = `${REACT_APP_TITLE_PREFIX} - ${pageTitleSuffix}`;

    return pageTitle;
  };

  const makeRoute = (route: IRouteInterface) => {
    const pageTitle = makeTitle(route.title, route.label);

    switch (route.type) {
      case 'private':
        return <PrivateRoute title={pageTitle} {...route} />;
      case 'public':
        return <PublicRoute title={pageTitle} {...route} />;
      default:
        return <AuthRoute title={pageTitle} {...route} />;
    }
  };

  return (
    <BrowserRouter>
      <Switch>
        {routes.map(({ sub_routes, ...route }) => [
          makeRoute(route),
          sub_routes?.map((sub_route) => makeRoute(sub_route)),
        ])}
      </Switch>
    </BrowserRouter>
  );
};

export default Routes;
