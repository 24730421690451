import { FC } from 'react';
import { Route, Switch, useRouteMatch } from 'react-router';

import List from './List';
import Save from './Save';

const OfficerTab: FC = () => {
  const { path } = useRouteMatch();

  return (
    <Switch>
      <Route exact path={path} component={List} />
      <Route path={`${path}/novo`} component={Save} />
      <Route path={`${path}/:responsibleId`} component={Save} />
    </Switch>
  );
};

export default OfficerTab;
