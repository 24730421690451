import { FC, useContext } from 'react';

import Divider from '@material-ui/core/Divider';
import IconButton from '@material-ui/core/IconButton';
import List from '@material-ui/core/List';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import PrivateContext from 'src/routes/Private/PrivateContext';

import Item from './Item';
import MenuItems from './menu-items';
import { Drawer, ToolbarIcon } from './styles';

const SideNavigation: FC = () => {
  const { sideMenuIsOpen, closeSideMenu } = useContext(PrivateContext);

  return (
    <Drawer variant="persistent" open={sideMenuIsOpen}>
      <ToolbarIcon>
        <IconButton onClick={closeSideMenu}>
          <ChevronLeftIcon />
        </IconButton>
      </ToolbarIcon>

      <Divider />

      <List>
        {MenuItems.map((item) => (
          <Item key={item.primaryText} item={item} />
        ))}
      </List>
    </Drawer>
  );
};

export default SideNavigation;
