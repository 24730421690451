import styled from 'styled-components';

import MuiBlockIcon from '@material-ui/icons/Block';
import MuiCheckIcon from '@material-ui/icons/Check';
import MuiCloseIcon from '@material-ui/icons/Close';
import MuiHelpOutlineIcon from '@material-ui/icons/HelpOutline';
import MuiHourglassEmptyIcon from '@material-ui/icons/HourglassEmpty';
import MuiNotificationImportantIcon from '@material-ui/icons/NotificationImportant';

export const HelpOutlineIcon = styled(MuiHelpOutlineIcon)`
  color: ${({ theme }) => theme.palette.primary.main};
`;

export const CheckIcon = styled(MuiCheckIcon)`
  color: ${({ theme }) => theme.palette.success.main};
`;

export const CloseIcon = styled(MuiCloseIcon)`
  color: ${({ theme }) => theme.palette.error.main};
`;

export const PendingIcon = styled(MuiHourglassEmptyIcon)`
  color: ${({ theme }) => theme.palette.warning.main};
`;

export const RejectedIcon = styled(MuiBlockIcon)`
  color: ${({ theme }) => theme.palette.error.main};
`;

export const NotifiedIcon = styled(MuiNotificationImportantIcon)`
  color: ${({ theme }) => theme.palette.warning.dark};
`;
