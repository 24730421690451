import { FC, useRef } from 'react';
import { useParams, useRouteMatch } from 'react-router';

import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Grid from '@material-ui/core/Grid';
import { GridColDef } from '@material-ui/data-grid';
import AddIcon from '@material-ui/icons/Add';
import FilterDrawer from 'src/components/FilterDrawer';
import { FilterDrawerRef } from 'src/components/FilterDrawer/interfaces';
import LinkButton from 'src/components/LinkButton';
import Table from 'src/components/Table';
import TableActions from 'src/components/Table/Actions';
import StatusIcon from 'src/components/Table/StatusIcon';
import { columnDate, columnDateTime } from 'src/components/Table/types';
import usePagination from 'src/hooks/usePagination';
import { IEditPartnerParams } from 'src/interfaces/forms/IPartner';
import { IPartnerContract } from 'src/interfaces/models/IPartner';
import PartnerContractFilterSchema from 'src/validators/PartnerContract/filter.schema';

import Filters from './Filters';
import { Container } from './styles';

const List: FC = () => {
  const filterRef = useRef<FilterDrawerRef>(null);
  const params = useParams<IEditPartnerParams>();
  const { url } = useRouteMatch();
  const {
    handlePageChange,
    handlePageSizeChange,
    data,
    loading,
    info,
    loadData,
  } = usePagination<IPartnerContract>(
    `/beneficiary-admin/partners/${params.id}/contracts`,
  );

  const columns: GridColDef[] = [
    { field: 'id', headerName: 'ID' },
    { field: 'signed_at', headerName: 'Assinado Em', flex: 1, ...columnDate },
    { field: 'notify_at', headerName: 'Notificar Em', flex: 1, ...columnDate },
    { field: 'expire_at', headerName: 'Expira Em', flex: 1, ...columnDate },
    {
      field: 'status',
      headerName: 'Status',
      headerAlign: 'center',
      align: 'center',
      renderCell(params) {
        return <StatusIcon status={params.row.status} />;
      },
    },
    {
      field: 'created_at',
      headerName: 'Criado Em',
      flex: 1,
      ...columnDateTime,
    },
    {
      field: '',
      disableColumnMenu: true,
      sortable: false,
      align: 'right',
      renderCell({ id, row }) {
        return (
          <TableActions
            resource="Contrato"
            resourceStatus={row.status}
            editLink={`${url}/${id}`}
            deleteApiUrl={`/beneficiary-admin/partners/${params.id}/contracts/${id}`}
            restoreApiUrl={`/beneficiary-admin/partners/${params.id}/contracts/${id}/restore`}
            descriptionPrefix={`O contrato ${id}`}
            onFinish={loadData}
          />
        );
      },
    },
  ];

  return (
    <Container>
      <FilterDrawer
        ref={filterRef}
        columns={columns.filter((column) => !column.hide && column.field)}
        yupSchema={PartnerContractFilterSchema}
      >
        <Filters />
      </FilterDrawer>

      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Grid container justify="flex-end" alignItems="center">
            <LinkButton
              to={`${url}/novo`}
              startIcon={<AddIcon />}
              color="primary"
              variant="contained"
              size="small"
            >
              Novo
            </LinkButton>
          </Grid>
        </Grid>

        <Grid item xs={12}>
          <Card>
            <CardContent>
              <Table
                autoHeight
                rows={data}
                page={info.current_page - 1}
                pageSize={info.per_page}
                rowCount={info.total}
                columns={columns}
                loading={loading}
                pagination
                paginationMode="server"
                onPageChange={handlePageChange}
                onPageSizeChange={handlePageSizeChange}
                onFilterClick={filterRef.current?.open}
              />
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    </Container>
  );
};

export default List;
