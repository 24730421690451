import { FC } from 'react';
import Helmet from 'react-helmet';
import { Provider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';
import { ThemeProvider } from 'styled-components';

import CssBaseline from '@material-ui/core/CssBaseline';
import { MuiThemeProvider } from '@material-ui/core/styles';
import { PersistGate } from 'redux-persist/integration/react';
import Toast from 'src/components/Toast';
import Routes from 'src/routes';
import { store, persistor } from 'src/store';
import MuiTheme from 'src/styles/themes/default/_MuiTheme';

const App: FC = () => {
  return (
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <Helmet>
          <link
            rel="stylesheet"
            href="https://fonts.googleapis.com/css2?family=Open+Sans:wght@400;600;700;800&display=swap"
          />
        </Helmet>
        <BrowserRouter>
          <MuiThemeProvider theme={MuiTheme}>
            <ThemeProvider theme={MuiTheme}>
              <CssBaseline />
              <Routes />
              <Toast />
            </ThemeProvider>
          </MuiThemeProvider>
        </BrowserRouter>
      </PersistGate>
    </Provider>
  );
};

export default App;
