import { BaseSchema } from 'yup';
import Reference from 'yup/lib/Reference';

export function requiredWhen(
  this: BaseSchema,
  ref: Reference<any>,
  refLabel?: string,
): any {
  return this.test({
    name: 'requiredWhen',
    exclusive: false,
    message: '${path} é obrigatório quando ${reference} existir',
    params: {
      reference: refLabel || ref.path,
    },
    test: function (value: any) {
      return !value ? !this.resolve(ref) : true;
    },
  });
}
