import { FC, useContext } from 'react';

import PrivateContext from 'src/routes/Private/PrivateContext';

import SideNavigation from './SideNavigation';
import { Container, Content, AppBarSpacer, LoadingProgress } from './styles';
import TopNavigation from './TopNavigation';

const PrivateLayout: FC = ({ children }) => {
  const { sideMenuIsOpen, layoutLoading } = useContext(PrivateContext);

  return (
    <Container>
      <TopNavigation />
      <SideNavigation />
      <Content isOpen={sideMenuIsOpen}>
        <AppBarSpacer>{layoutLoading && <LoadingProgress />}</AppBarSpacer>
        {children}
      </Content>
    </Container>
  );
};

export default PrivateLayout;
