import { IRouteInterface } from 'src/interfaces/IRoutes';

import AuthPages from '../pages/Auth';
import PrivatePages from '../pages/Private';
import PublicPages from '../pages/Public';

export const authRoutes: IRouteInterface[] = [
  {
    exact: true,
    component: AuthPages.Login,
    label: 'Login',
    path: '/',
    type: 'auth',
  },
  {
    component: AuthPages.ForgotPassword,
    exact: true,
    label: 'Esqueci minha senha',
    path: '/esqueci-minha-senha',
    type: 'auth',
  },
  {
    component: AuthPages.RecoverPassword,
    label: 'Recuperar senha',
    path: '/recuperar-senha/:token',
    type: 'auth',
  },
];

export const privateRoutes: IRouteInterface[] = [
  {
    exact: true,
    component: PrivatePages.Partners.List,
    label: 'Parceiras',
    path: '/parceiras',
    type: 'private',
  },
  {
    component: PrivatePages.Partners.Save,
    label: 'Nova Parceira',
    path: '/parceiras/novo',
    type: 'private',
  },
  {
    component: PrivatePages.Partners.Save,
    label: 'Editar Parceira',
    path: '/parceiras/:id/:selectedTab?',
    type: 'private',
  },
];

export const publicRoutes: IRouteInterface[] = [
  {
    component: PublicPages.NotFound,
    label: 'Página não encontrada',
    path: '*',
    type: 'public',
  },
];

export default [...authRoutes, ...privateRoutes, ...publicRoutes];
