import yup from 'src/libs/yup';

const PartnerSubsidiaryFilterSchema = yup.object().shape({
  zip_code: yup.string().label('CEP'),
  state: yup.string().label('UF'),
  city: yup.string().label('Cidade'),
  neighborhood: yup.string().label('Bairro'),
  street: yup.string().label('Rua/Av'),
  number: yup.string().label('Número'),
  complement: yup.string().label('Complemento'),
  status: yup.string().nullable().label('Status'),
});

export default PartnerSubsidiaryFilterSchema;
