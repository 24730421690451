import yup from 'src/libs/yup';

const PartnerResponsibleFilterSchema = yup.object().shape({
  name: yup.string().label('Nome'),
  document: yup.string().label('CPF'),
  email: yup.string().label('E-mail'),
  phone: yup.string().label('Telefone'),
  department: yup.string().label('Departamento'),
  legal: yup.boolean(),
  partnership: yup.boolean(),
  status: yup.string().nullable().label('Status'),
});

export default PartnerResponsibleFilterSchema;
