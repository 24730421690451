import {
  forwardRef,
  ForwardRefRenderFunction,
  useImperativeHandle,
  useState,
} from 'react';

import Dialog from '@material-ui/core/Dialog';

import { CustomDialogProps, CustomDialogRef } from './interfaces';

const ConfirmDialog: ForwardRefRenderFunction<
  CustomDialogRef,
  CustomDialogProps
> = ({ children, ...rest }, ref) => {
  const [open, setOpen] = useState(false);

  useImperativeHandle(ref, () => ({
    show: () => setOpen(true),
    hide: () => setOpen(false),
  }));

  return (
    <Dialog
      open={open}
      onClose={() => setOpen(false)}
      fullWidth
      maxWidth="xs"
      {...rest}
    >
      {children}
    </Dialog>
  );
};

export default forwardRef(ConfirmDialog);
