import { Typography } from '@material-ui/core';
import { GridColTypeDef } from '@material-ui/data-grid';
import dateFormat from 'date-fns/format';
import isValid from 'date-fns/isValid';
import ptBR from 'date-fns/locale/pt-BR';
import parseISO from 'date-fns/parseISO';
import masks from 'src/utils/masks';

export const columnDate: GridColTypeDef = {
  type: 'date',
  valueFormatter: ({ value }) => {
    const date = parseISO(value ? value.toString() : '');

    return isValid(date)
      ? dateFormat(date, 'dd/MM/yyyy', { locale: ptBR })
      : '';
  },
};

export const columnDateTime: GridColTypeDef = {
  type: 'datetime',
  headerAlign: 'center',
  align: 'center',
  width: 100,
  renderCell({ value }) {
    const date = parseISO(value ? value.toString() : '');

    if (!isValid(date)) {
      return <div />;
    }

    return (
      <div style={{ flex: 1 }}>
        <Typography variant="body2">
          {dateFormat(date, 'dd/MM/yyyy', { locale: ptBR })}
        </Typography>
        <Typography variant="body2">
          {dateFormat(date, 'HH:mm', { locale: ptBR })}
        </Typography>
      </div>
    );
  },
};

export const columnPercent: GridColTypeDef = {
  type: 'percent',
  valueFormatter: ({ value }) => {
    const percent = Number(value) / 100;
    return `${percent || 0}%`;
  },
};

export const columnPhone: GridColTypeDef = {
  type: 'phone',
  valueFormatter: ({ value }) => {
    return masks.phoneOrCellphone(value ? String(value) : '');
  },
};
