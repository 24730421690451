import { FC, useRef, useState } from 'react';
import { useParams, useRouteMatch } from 'react-router';

import { DialogActions, DialogContent, DialogTitle } from '@material-ui/core';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import { GridColDef, GridRowData } from '@material-ui/data-grid';
import AddIcon from '@material-ui/icons/Add';
import PermContactIcon from '@material-ui/icons/PermContactCalendar';
import Button from 'src/components/Button';
import CustomDialog from 'src/components/CustomDialog';
import { CustomDialogRef } from 'src/components/CustomDialog/interfaces';
import FilterDrawer from 'src/components/FilterDrawer';
import { FilterDrawerRef } from 'src/components/FilterDrawer/interfaces';
import LinkButton from 'src/components/LinkButton';
import Table from 'src/components/Table';
import TableActions from 'src/components/Table/Actions';
import CustomMenuItem from 'src/components/Table/CustomMenuItem';
import StatusIcon from 'src/components/Table/StatusIcon';
import usePagination from 'src/hooks/usePagination';
import { IEditPartnerParams } from 'src/interfaces/forms/IPartner';
import { IPartnerResponsible } from 'src/interfaces/models/IPartner';
import masks from 'src/utils/masks';
import PartnerResponsibleFilterSchema from 'src/validators/PartnerResponsible/filter.schema';

import Filters from './Filters';
import { Container } from './styles';

const List: FC = () => {
  const filterRef = useRef<FilterDrawerRef>(null);
  const infoDialogRef = useRef<CustomDialogRef>(null);
  const [
    currentResponsible,
    setCurrentResponsible,
  ] = useState<IPartnerResponsible>();
  const params = useParams<IEditPartnerParams>();
  const { url } = useRouteMatch();
  const {
    handlePageChange,
    handlePageSizeChange,
    data,
    loading,
    info,
    loadData,
  } = usePagination<IPartnerResponsible>(
    `/beneficiary-admin/partners/${params.id}/responsibles`,
  );

  function getResponsabilities(responsible?: IPartnerResponsible) {
    if (!responsible) return;
    const { legal, partnership } = responsible;
    const values = new Map();
    values.set('Legal', legal);
    values.set('Parceria', partnership);

    const resp: string[] = [];
    for (const [key, val] of values) {
      if (val) resp.push(key);
    }

    return resp.join(' - ');
  }

  const handleOpenInfoDialog = (gridRow: GridRowData) => {
    setCurrentResponsible(data.find((resp) => resp.id == gridRow.id));
    infoDialogRef.current?.show();
  };

  const columns: GridColDef[] = [
    { field: 'id', headerName: 'ID' },
    {
      field: 'name',
      headerName: 'Nome',
      flex: 1,
      renderCell({ row }) {
        return (
          <div>
            <Typography variant="body2">{row.name}</Typography>
            <Typography variant="body2">{masks.cpf(row.document)}</Typography>
          </div>
        );
      },
    },
    {
      field: 'email',
      headerName: 'Contato',
      flex: 1,
      renderCell({ row }) {
        return (
          <div>
            <Typography variant="body2">{row.email}</Typography>
            <Typography variant="body2">
              {masks.phoneOrCellphone(row.phone)}
            </Typography>
          </div>
        );
      },
    },
    {
      field: 'responsability',
      headerName: 'Responsabilidades',
      align: 'center',
      renderCell({ row }) {
        return (
          <div>
            <Typography variant="body2">{row.legal && 'Legal'}</Typography>
            <Typography variant="body2">
              {row.partnership && 'Parceria'}
            </Typography>
          </div>
        );
      },
    },
    {
      field: 'status',
      headerName: 'Status',
      headerAlign: 'center',
      align: 'center',
      renderCell(params) {
        return <StatusIcon status={params.row.status} />;
      },
    },
    {
      field: '',
      disableColumnMenu: true,
      sortable: false,
      align: 'right',
      renderCell({ id, row }) {
        return (
          <TableActions
            resource="Responsável"
            resourceStatus={row.status}
            editLink={`${url}/${id}`}
            deleteApiUrl={`/beneficiary-admin/partners/${params.id}/responsibles/${id}`}
            restoreApiUrl={`/beneficiary-admin/partners/${params.id}/responsibles/${id}/restore`}
            descriptionPrefix={`O responsável ${row.name}`}
            onFinish={loadData}
            startItems={
              <>
                <CustomMenuItem
                  Icon={PermContactIcon}
                  iconProps={{ color: 'primary' }}
                  text="Exibir Contatos"
                  onClick={() => handleOpenInfoDialog(row)}
                />
              </>
            }
          />
        );
      },
    },
  ];

  return (
    <Container>
      <FilterDrawer
        ref={filterRef}
        columns={columns.filter((column) => !column.hide && column.field)}
        yupSchema={PartnerResponsibleFilterSchema}
      >
        <Filters />
      </FilterDrawer>

      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Grid container justify="flex-end" alignItems="center">
            <LinkButton
              to={`${url}/novo`}
              startIcon={<AddIcon />}
              color="primary"
              variant="contained"
              size="small"
            >
              Novo
            </LinkButton>
          </Grid>
        </Grid>

        <Grid item xs={12}>
          <Card>
            <CardContent>
              <Table
                autoHeight
                rows={data}
                page={info.current_page - 1}
                pageSize={info.per_page}
                rowCount={info.total}
                columns={columns}
                loading={loading}
                pagination
                paginationMode="server"
                onPageChange={handlePageChange}
                onPageSizeChange={handlePageSizeChange}
                onFilterClick={filterRef.current?.open}
              />
            </CardContent>
          </Card>
        </Grid>
      </Grid>

      <CustomDialog ref={infoDialogRef}>
        <DialogTitle>Contatos de {currentResponsible?.name}</DialogTitle>

        <DialogContent>
          {currentResponsible?.document && (
            <Typography variant="body1">
              <strong>CPF:</strong>{' '}
              {masks.cpfOrCnpj(currentResponsible?.document)}
            </Typography>
          )}
          {currentResponsible?.department && (
            <Typography variant="body1">
              <strong>Departamento:</strong> {currentResponsible?.department}
            </Typography>
          )}
          {currentResponsible?.email && (
            <Typography variant="body1">
              <strong>E-mail Principal:</strong> {currentResponsible?.email}
            </Typography>
          )}
          {currentResponsible?.phone && (
            <Typography variant="body1">
              <strong>Telefone Principal:</strong>{' '}
              {masks.phoneOrCellphone(currentResponsible?.phone)}
            </Typography>
          )}
          {getResponsabilities(currentResponsible) && (
            <Typography variant="body1">
              <strong>Responsabilidades:</strong>{' '}
              {getResponsabilities(currentResponsible)}
            </Typography>
          )}
          {currentResponsible?.contacts?.map((contact) => (
            <Typography key={contact.id} variant="body1">
              <strong>{contact.contact_type}:</strong> {contact.contact_value}
            </Typography>
          ))}
        </DialogContent>

        <DialogActions>
          <Button
            color="primary"
            variant="contained"
            onClick={() => infoDialogRef.current?.hide()}
          >
            Fechar
          </Button>
        </DialogActions>
      </CustomDialog>
    </Container>
  );
};

export default List;
