import yup from 'src/libs/yup';
import { operators } from 'src/utils/helpers';

const oneOfOpearators = operators
  .filter((op) => !!op.value)
  .map((op) => op.value);

const PartnerServiceFilterSchema = yup.object().shape({
  discount: yup
    .object()
    .optional()
    .shape({
      operator: yup
        .string()
        .transform((value) => (value !== '' ? value : null))
        .nullable()
        .oneOf([null, ...oneOfOpearators])
        .requiredWhen(yup.ref('value'), 'Valor')
        .label('Operador'),
      value: yup
        .string()
        .requiredWhen(yup.ref('operator'), 'Operador')
        .when('operator', {
          is: 'between',
          then: yup.string().label('De'),
          otherwise: yup.string().label('Valor'),
        }),
      value_end: yup
        .string()
        .when('operator', {
          is: 'between',
          then: yup.string().required(),
          otherwise: yup.string().optional(),
        })
        .label('Até'),
    })
    .label('Desconto'),
  service_id: yup.string().nullable().label('Serviço'),
  teach_form_id: yup.string().nullable().label('Forma de Ensino'),
});

export default PartnerServiceFilterSchema;
