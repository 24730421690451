import { AnySchema } from 'yup';

export function isFile(this: AnySchema, msg?: string): any {
  return this.test({
    name: 'isFile',
    exclusive: false,
    message: msg || '${path} não é um arquivo.',
    test(value) {
      if (!value) {
        return true;
      }

      return value instanceof File;
    },
  });
}
