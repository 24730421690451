import { FC } from 'react';

import { ITabPanelProps } from 'src/interfaces/ITabPanel';

const TabPanel: FC<ITabPanelProps> = ({ index, value, children }) => {
  return (
    <div role="tabpanel" hidden={value !== index}>
      {value === index && children}
    </div>
  );
};

export default TabPanel;
