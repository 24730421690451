import styled from 'styled-components';

export const Container = styled.div`
  .ck-content {
    min-height: 200px;
    max-height: 400px;

    > * {
      margin: 0;
    }
  }
`;
