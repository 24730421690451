import { FC, useContext, useEffect, useRef, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';

import { IconButton } from '@material-ui/core';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import AddIcon from '@material-ui/icons/Add';
import CheckIcon from '@material-ui/icons/Check';
import CloseIcon from '@material-ui/icons/Close';
import DeleteIcon from '@material-ui/icons/Delete';
import RestoreIcon from '@material-ui/icons/Restore';
import { SubmitHandler, FormHandles, Scope } from '@unform/core';
import { Form } from '@unform/web';
import Button from 'src/components/Button';
import ConfirmDialog from 'src/components/ConfirmDialog';
import { ConfirmDialogRef } from 'src/components/ConfirmDialog/interfaces';
import CheckBox from 'src/components/Form/Checkbox';
import TextField from 'src/components/Form/TextField';
import { IEditPartnerResponsibleParams } from 'src/interfaces/forms/IPartner';
import { IPartnerResponsible } from 'src/interfaces/models/IPartner';
import PrivateContext from 'src/routes/Private/PrivateContext';
import api from 'src/services/api';
import { handleApiResponseErrors, showFormErrors } from 'src/utils/errors';
import yupValidate from 'src/utils/yupValidate';
import {
  PartnerResponsibleStoreSchema,
  PartnerResponsibleUpdateSchema,
} from 'src/validators/PartnerResponsible/save.schema';

import { Container } from './styles';

interface IContact {
  key: number;
  id?: number;
  contact_type?: string;
  contact_value?: string;
}

const Save: FC = () => {
  const { startLayoutLoading, stopLayoutLoading } = useContext(PrivateContext);
  const formRef = useRef<FormHandles>(null);
  const confirmDialogRef = useRef<ConfirmDialogRef>(null);
  const [loading, setLoading] = useState(false);
  const [status, setStatus] = useState('');
  const [contacts, setContacts] = useState<IContact[]>([]);
  const history = useHistory();
  const params = useParams<IEditPartnerResponsibleParams>();

  const yupSchema = params.responsibleId
    ? PartnerResponsibleUpdateSchema
    : PartnerResponsibleStoreSchema;

  useEffect(() => {
    const loadData = async () => {
      try {
        startLayoutLoading();

        if (params.responsibleId) {
          const userResponse = await api.get(
            `/beneficiary-admin/partners/${params.id}/responsibles/${params.responsibleId}`,
          );
          const responsible = userResponse.data as IPartnerResponsible;

          setStatus(responsible.status);
          const currentContacts =
            responsible.contacts?.map((contact) => ({
              key: contact.id,
              id: contact.id,
              contact_type: contact.contact_type,
              contact_value: contact.contact_value,
            })) || [];
          setContacts(currentContacts);

          setTimeout(() => {
            formRef.current?.setData(responsible);
          });
        }
      } catch (error) {
        handleApiResponseErrors(error.response, 'Erro ao buscar dados.');
      } finally {
        stopLayoutLoading();
      }
    };

    loadData();
  }, [params.id, params.responsibleId, startLayoutLoading, stopLayoutLoading]);

  const handleOnSubmit: SubmitHandler = async (formData) => {
    try {
      setLoading(true);
      formRef.current?.setErrors({});

      const { success, data, errors } = await yupValidate(yupSchema, formData);

      if (!success) {
        return showFormErrors(errors, formRef);
      }

      if (params.responsibleId) {
        await api.put(
          `/beneficiary-admin/partners/${params.id}/responsibles/${params.responsibleId}`,
          data,
        );
      } else {
        await api.post(
          `/beneficiary-admin/partners/${params.id}/responsibles`,
          data,
        );
      }

      toast.success('Dados salvos com sucesso!');
      history.goBack();
    } catch (error) {
      handleApiResponseErrors(error.response, 'Erro', yupSchema, formRef);
    } finally {
      setLoading(false);
    }
  };

  const handleConfirm = async () => {
    try {
      setLoading(true);

      if (status === 'active') {
        const isDelete = confirmDialogRef.current?.isDelete();
        let deleteConfig = {};
        if (isDelete) {
          deleteConfig = { data: { delete: true } };
        }

        await api.delete(
          `/beneficiary-admin/partners/${params.id}/responsibles/${params.responsibleId}`,
          deleteConfig,
        );

        toast.success(`Responsável ${isDelete ? 'excluido' : 'desativado'}!`);
      } else {
        await api.post(
          `/beneficiary-admin/partners/${params.id}/responsibles/${params.responsibleId}/restore`,
        );
        toast.success('Responsável restaurado!');
      }
      history.goBack();
    } catch (error) {
      handleApiResponseErrors(
        error.response,
        'Erro ao atualizar representante.',
      );
    } finally {
      setLoading(false);
    }
  };

  const handleRemoveContact = (index: number) => {
    setContacts((state) =>
      state.filter((_, stateIndex) => stateIndex !== index),
    );
  };

  return (
    <Container maxWidth="md">
      <Form ref={formRef} onSubmit={handleOnSubmit} noValidate>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Typography variant="h6">
              {params.responsibleId ? 'Editar' : 'Novo'} Responsável
            </Typography>
          </Grid>

          <Grid item xs={12}>
            <Card>
              <CardContent>
                <Grid container spacing={1}>
                  <Grid item xs={12} sm={4} md={3}>
                    <TextField
                      name="document"
                      label="CPF"
                      mask="cpf"
                      returnUnmasked
                    />
                  </Grid>

                  <Grid item xs={12} sm={8} md={9}>
                    <TextField name="name" label="Nome" required />
                  </Grid>

                  <Grid item xs={12} sm={3}>
                    <TextField name="department" label="Departamento" />
                  </Grid>

                  <Grid item xs={12} sm={6}>
                    <TextField name="email" label="E-mail" />
                  </Grid>

                  <Grid item xs={12} sm={3}>
                    <TextField
                      name="phone"
                      label="Telefone"
                      mask="phoneOrCellphone"
                      returnUnmasked
                    />
                  </Grid>

                  <Grid item xs={12}>
                    <Typography variant="subtitle1">
                      Responsabilidades
                    </Typography>
                  </Grid>

                  <Grid item xs={12}>
                    <CheckBox name="legal" label="Legal" />
                    <CheckBox name="partnership" label="Parceria" />
                  </Grid>
                </Grid>
              </CardContent>
            </Card>
          </Grid>

          <Grid item xs={12}>
            <Card>
              <CardContent>
                <Grid container spacing={1} alignItems="center">
                  <Grid item xs={12}>
                    <Grid container justify="space-between">
                      <Typography color="textSecondary">
                        Contatos Extras
                      </Typography>

                      <Button
                        startIcon={<AddIcon />}
                        variant="outlined"
                        color="primary"
                        size="small"
                        onClick={() =>
                          setContacts((state) =>
                            state.concat({ key: Math.random() }),
                          )
                        }
                      >
                        Contato
                      </Button>
                    </Grid>
                  </Grid>

                  {contacts.map((contact, index) => (
                    <Scope key={contact.key} path={`contacts[${index}]`}>
                      <TextField name="id" hidden value={contact.id} />
                      <Grid item xs={12} sm={4}>
                        <TextField
                          name="contact_type"
                          label="Tipo"
                          placeholder="E-mail, Telefone, etc..."
                          required
                        />
                      </Grid>

                      <Grid item xs={12} sm={6}>
                        <TextField
                          name="contact_value"
                          label="Contato"
                          required
                        />
                      </Grid>

                      <Grid item xs={12} sm={2} style={{ textAlign: 'center' }}>
                        <IconButton
                          tabIndex="-1"
                          color="secondary"
                          onClick={() => handleRemoveContact(index)}
                        >
                          <DeleteIcon />
                        </IconButton>
                      </Grid>
                    </Scope>
                  ))}
                </Grid>
              </CardContent>
            </Card>
          </Grid>

          <Grid item xs={12}>
            <Grid container justify="space-between">
              {status === 'active' ? (
                <Button
                  type="button"
                  loading={loading}
                  startIcon={<CloseIcon />}
                  variant="contained"
                  color="secondary"
                  disabled={!params.responsibleId}
                  onClick={() => confirmDialogRef.current?.show()}
                >
                  Desativar
                </Button>
              ) : (
                <Button
                  type="button"
                  loading={loading}
                  startIcon={<RestoreIcon />}
                  color="primary"
                  disabled={!params.responsibleId}
                  onClick={() => confirmDialogRef.current?.show()}
                >
                  Restaurar
                </Button>
              )}

              <Button
                type="submit"
                loading={loading}
                startIcon={<CheckIcon />}
                variant="contained"
                color="primary"
              >
                Salvar
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </Form>

      <ConfirmDialog
        ref={confirmDialogRef}
        title={`${status === 'active' ? 'Desativar' : 'Restaurar'} responsável`}
        description="Confirma esta ação?"
        confirmColor={status === 'active' ? 'secondary' : 'primary'}
        onConfirm={handleConfirm}
        canDelete={status === 'active'}
      />
    </Container>
  );
};

export default Save;
