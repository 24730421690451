import styled from 'styled-components';

import { Form as Unform } from '@unform/web';
import Button from 'src/components/Button';

export const Content = styled.div`
  height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

export const Form = styled(Unform)`
  width: 100%;
  margin-top: ${({ theme }) => theme.spacing(1)}px;
`;

export const SubmitButton = styled(Button)`
  margin: ${({ theme }) => theme.spacing(3, 0, 2)};
`;
