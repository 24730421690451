import yup from 'src/libs/yup';

export const PartnerContractStoreSchema = yup.object().shape({
  signed_at: yup.string().dateFormat('dd/MM/yyyy').label('Assinado Em'),
  notify_at: yup.string().dateFormat('dd/MM/yyyy').label('Notificar Em'),
  expire_at: yup.string().dateFormat('dd/MM/yyyy').label('Expira Em'),
});

export const PartnerContractUpdateSchema = yup.object().shape({
  signed_at: yup.string().dateFormat('dd/MM/yyyy').label('Assinado Em'),
  notify_at: yup.string().dateFormat('dd/MM/yyyy').label('Notificar Em'),
  expire_at: yup.string().dateFormat('dd/MM/yyyy').label('Expira Em'),
});
