import { FC, useCallback, useEffect, useRef } from 'react';

import '@ckeditor/ckeditor5-build-classic/build/translations/pt-br';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import FormHelperText from '@material-ui/core/FormHelperText';
import { useField } from '@unform/core';
import CustomEditor from 'ckeditor5-custom-build';

import { RichTextProps, RichTextRef, defaultConfig } from './interfaces';
import { Container } from './styles';

const RichText: FC<RichTextProps> = ({ name, config, onChange, ...rest }) => {
  const inputRef = useRef<RichTextRef>({ value: '' });

  const {
    fieldName,
    registerField,
    error,
    defaultValue,
    clearError,
  } = useField(name);

  useEffect(() => {
    registerField<string>({
      name: fieldName,
      ref: inputRef,
      getValue: (ref) => {
        return ref.current?.value || '';
      },
      setValue: (ref, newValue) => {
        ref.current.value = newValue || '';
        clearError();
      },
      clearValue: (ref, newValue) => {
        ref.current.value = newValue || '';
        clearError();
      },
    });
  }, [fieldName, registerField, clearError]);

  useEffect(() => {
    if (defaultValue) {
      inputRef.current.value = defaultValue;
    }
  }, [defaultValue]);

  const handleChange = useCallback<NonNullable<RichTextProps['onChange']>>(
    (event, editor) => {
      inputRef.current.value = editor.getData();

      clearError();
      if (onChange) onChange(event, editor);
    },
    [clearError, onChange],
  );

  return (
    <Container>
      <CKEditor
        editor={CustomEditor}
        config={{ ...defaultConfig, ...config }}
        onChange={handleChange}
        data={inputRef.current.value}
        {...rest}
      />
      {error && <FormHelperText error={!!error}>{error}</FormHelperText>}
    </Container>
  );
};

export default RichText;
