import { CKEditorProps } from '@ckeditor/ckeditor5-react';

export interface RichTextProps extends Omit<CKEditorProps, 'editor' | 'data'> {
  name: string;
}

export interface RichTextRef {
  value: string;
}

export const defaultConfig: CKEditorProps['config'] = {
  language: 'pt-br',
  toolbar: {
    shouldNotGroupWhenFull: true,
    removeItems: ['imageUpload'],
    // items: [
    //   'heading',
    //   '|',
    //   'alignment',
    //   '|',
    //   'bold',
    //   'italic',
    //   // 'strikethrough',
    //   // 'underline',
    //   // 'subscript',
    //   // 'superscript',
    //   '|',
    //   'link',
    //   '|',
    //   'bulletedList',
    //   'numberedList',
    //   // 'todoList',
    //   '-', // break point
    //   // 'fontfamily',
    //   // 'fontsize',
    //   // 'fontColor',
    //   // 'fontBackgroundColor',
    //   '|',
    //   // 'code',
    //   // 'codeBlock',
    //   // '|',
    //   'insertTable',
    //   '|',
    //   'outdent',
    //   'indent',
    //   '|',
    //   // 'uploadImage',
    //   'blockQuote',
    //   '|',
    //   'undo',
    //   'redo',
    // ],
  },
};
