import { FC, useCallback, useEffect, useRef, useState } from 'react';
import { useHistory, useLocation } from 'react-router';

import { Button, Grid, IconButton, InputAdornment } from '@material-ui/core';
import { DataGrid, GridSortModel } from '@material-ui/data-grid';
import CloseIcon from '@material-ui/icons/Close';
import SearchIcon from '@material-ui/icons/Search';
import { FormHandles } from '@unform/core';
import { Form } from '@unform/web';
import TextField from 'src/components/Form/TextField';

import CustomToolbar from './CustomToolbar';
import { TableProps } from './interfaces';

const Table: FC<TableProps> = ({
  columns,
  onFilterClick,
  canSearch = false,
  ...rest
}) => {
  const [currentSortModel, setCurrentSortModel] = useState<GridSortModel>([]);
  const searchFormRef = useRef<FormHandles>(null);
  const [hasSearch, setHasSearch] = useState(false);
  const history = useHistory();
  const location = useLocation();

  useEffect(() => {
    const query = new URLSearchParams(location.search);
    const column = query.get('order_by[0][column]');
    const sort = query.get('order_by[0][direction]') === 'asc' ? 'asc' : 'desc';

    if (column) {
      setCurrentSortModel([
        {
          field: column,
          sort: sort,
        },
      ]);
    } else {
      setCurrentSortModel([]);
    }

    setHasSearch(query.has('search'));

    const search = query.get('search');
    if (search) {
      searchFormRef.current?.setFieldValue('search', search);
    }
  }, [location.search]);

  const handleSearchSubmit = useCallback(
    (formData) => {
      const query = new URLSearchParams(location.search);

      if (formData.search) {
        query.set('search', formData.search);
      } else {
        query.delete('search');
      }

      history.replace({
        pathname: location.pathname,
        search: query.toString(),
      });
    },
    [location, history],
  );

  return (
    <>
      {canSearch && (
        <Form
          ref={searchFormRef}
          onSubmit={handleSearchSubmit}
          style={{ marginBottom: 4 }}
        >
          <Grid container spacing={1} alignItems="center" justify="flex-end">
            <Grid item xs={12} sm={6} md={4}>
              <TextField
                name="search"
                label="Buscar..."
                size="small"
                autoComplete="off"
                InputProps={{
                  startAdornment: hasSearch ? (
                    <InputAdornment position="start">
                      <IconButton
                        size="small"
                        color="secondary"
                        onClick={() => {
                          searchFormRef.current?.setFieldValue('search', '');
                          searchFormRef.current?.submitForm();
                        }}
                      >
                        <CloseIcon fontSize="inherit" />
                      </IconButton>
                    </InputAdornment>
                  ) : null,
                  endAdornment: (
                    <InputAdornment position="end">
                      <Button
                        size="small"
                        color="primary"
                        startIcon={<SearchIcon />}
                        onClick={searchFormRef.current?.submitForm}
                      >
                        Buscar
                      </Button>
                    </InputAdornment>
                  ),
                }}
              />
            </Grid>
          </Grid>
        </Form>
      )}

      <DataGrid
        components={{ Toolbar: CustomToolbar }}
        componentsProps={{ toolbar: { onFilterClick } }}
        pageSize={5}
        rowsPerPageOptions={[5, 10, 20]}
        columns={columns.map((column) => ({
          ...column,
          sortable:
            column.sortable !== undefined ? column.sortable : !!column.field,
        }))}
        disableColumnFilter
        disableColumnMenu
        disableSelectionOnClick
        sortingMode="server"
        sortModel={currentSortModel}
        onSortModelChange={(sortModel) => {
          const query = new URLSearchParams(location.search);

          if (sortModel.length) {
            if (currentSortModel[0] === sortModel[0]) return;

            query.set('order_by[0][column]', sortModel[0].field);
            query.set('order_by[0][direction]', sortModel[0].sort || 'asc');
          } else {
            query.delete('order_by[0][column]');
            query.delete('order_by[0][direction]');
          }

          history.replace({
            pathname: location.pathname,
            search: query.toString(),
          });
        }}
        {...rest}
      />
    </>
  );
};

export default Table;
