import { FC, useEffect, useState } from 'react';

import Divider from '@material-ui/core/Divider';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import { Scope } from '@unform/core';
import Select from 'src/components/Form/Select';
import { Item } from 'src/components/Form/Select/interfaces';
import TextField from 'src/components/Form/TextField';
import { IService, ITeachForm } from 'src/interfaces/models';
import api from 'src/services/api';
import { objectToQuery, operators } from 'src/utils/helpers';

const Filters: FC = () => {
  const [discountBetween, setDiscountBetween] = useState(false);
  const [serviceItems, setServiceItems] = useState<Item[]>([]);
  const [teachFormItems, setTeachFormItems] = useState<Item[]>([]);

  useEffect(() => {
    const loadData = async () => {
      try {
        const params = objectToQuery({
          order_by: [{ column: 'name', direction: 'asc' }],
        });
        const servicesResponse = await api.get(
          `/admin/services${params}&load_category=true`,
        );
        const services = servicesResponse.data as IService[];
        setServiceItems(
          services.map((service) => ({
            key: service.id,
            label: `${service.name} (${service.category?.name})`,
            value: service.id,
          })),
        );
        const teachFormResponse = await api.get(`/admin/teach-forms${params}`);
        const teachForms = teachFormResponse.data as ITeachForm[];
        setTeachFormItems(
          teachForms.map((teachForm) => ({
            key: teachForm.id,
            label: teachForm.name,
            value: teachForm.id,
          })),
        );
      } catch (error) {}
    };

    loadData();
  }, []);

  return (
    <>
      <Grid item xs={12}>
        <Divider />
      </Grid>

      <Scope path="discount">
        <Grid item xs={12}>
          <Typography variant="body1">Desconto</Typography>
        </Grid>
        <Grid item xs={4}>
          <Select
            name="operator"
            label="Operador"
            items={operators}
            onChange={(value: any) => setDiscountBetween(value === 'between')}
            size="small"
          />
        </Grid>

        <Grid item xs={discountBetween ? 4 : 8}>
          <TextField
            label={discountBetween ? 'De (%)' : 'Valor (%)'}
            name="value"
            size="small"
            mask="percent"
            returnUnmasked
          />
        </Grid>

        {discountBetween && (
          <Grid item xs={4}>
            <TextField
              label="Até (%)"
              name="value_end"
              size="small"
              mask="percent"
              returnUnmasked
            />
          </Grid>
        )}
      </Scope>

      <Grid item xs={12} sm={6}>
        <Select
          name="service_id"
          label="Serviço"
          items={serviceItems}
          size="small"
        />
      </Grid>

      <Grid item xs={12} sm={6}>
        <Select
          name="teach_form_id"
          label="Forma de Ensino"
          items={teachFormItems}
          size="small"
        />
      </Grid>
    </>
  );
};

export default Filters;
