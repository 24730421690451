import { FC } from 'react';

import Divider from '@material-ui/core/Divider';
import Grid from '@material-ui/core/Grid';
import Select from 'src/components/Form/Select';
import TextField from 'src/components/Form/TextField';
import { statesOptions } from 'src/utils/constants';

const solicitationItems = [
  { key: 1, label: 'Voucher', value: 'voucher' },
  { key: 2, label: 'Orçamento', value: 'budget' },
];

const statusItems = [
  { key: 1, label: 'Pendente', value: 'pending' },
  { key: 2, label: 'Ativo', value: 'active' },
  { key: 3, label: 'Inativo', value: 'inactive' },
];

const Filters: FC = () => {
  return (
    <>
      <Grid item xs={12}>
        <Divider />
      </Grid>

      <Grid item xs={12}>
        <TextField name="display_name" label="Nome" size="small" />
      </Grid>

      <Grid item xs={12} sm={6}>
        <TextField
          name="document"
          label="CNPJ"
          size="small"
          mask="cnpj"
          returnUnmasked
        />
      </Grid>
      <Grid item xs={12} sm={6}>
        <TextField name="group" label="Grupo" size="small" />
      </Grid>

      <Grid item xs={12} sm={6}>
        <TextField name="city" label="Cidade" size="small" />
      </Grid>
      <Grid item xs={12} sm={6}>
        <Select name="state" label="UF" items={statesOptions} size="small" />
      </Grid>

      <Grid item xs={6}>
        <Select
          name="solicitation"
          label="Solicitação"
          items={solicitationItems}
          size="small"
        />
      </Grid>

      <Grid item xs={6}>
        <Select name="status" label="Status" items={statusItems} size="small" />
      </Grid>
    </>
  );
};

export default Filters;
