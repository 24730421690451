import { useEffect, useState, useCallback } from 'react';
import { useHistory, useLocation } from 'react-router';

import { DataGridProps } from '@material-ui/data-grid';
import api from 'src/services/api';
import { handleApiResponseErrors } from 'src/utils/errors';
import { queryToObject, objectToQuery } from 'src/utils/helpers';

import { IUsePaginationReturn, INITIAL_INFO } from './interfaces';

function usePagination<T>(
  endpoint: string,
  defaultParams?: Record<string, any>,
): IUsePaginationReturn<T> {
  const [data, setData] = useState<T[]>([]);
  const [info, setInfo] = useState(INITIAL_INFO);
  const [loading, setLoading] = useState(false);
  const history = useHistory();
  const location = useLocation();

  const loadData = useCallback(async () => {
    try {
      setLoading(true);

      const query = new URLSearchParams(location.search);

      const params = queryToObject(query);

      if (!params.page) {
        params.page = String(INITIAL_INFO.first_page);
      }
      if (!params.per_page) {
        params.per_page = String(INITIAL_INFO.per_page);
      }

      const { data: responseData } = await api.get(
        endpoint + objectToQuery({ ...defaultParams, ...params }),
      );

      setInfo(responseData.meta || INITIAL_INFO);
      setData(responseData.data || []);
    } catch (error) {
      handleApiResponseErrors(
        error.response,
        'Ocorreu um erro na busca dos dados.',
      );
    } finally {
      setLoading(false);
    }
  }, [location.search, endpoint, defaultParams]);

  useEffect(() => {
    loadData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.search]);

  const handlePageChange: DataGridProps['onPageChange'] = (page) => {
    const query = new URLSearchParams(location.search);

    const params = queryToObject(query);

    params.page = String(page + 1);

    history.replace({
      pathname: location.pathname,
      search: objectToQuery(params, { addQueryPrefix: false }),
    });
  };

  const handlePageSizeChange: DataGridProps['onPageSizeChange'] = (
    pageSize,
  ) => {
    const query = new URLSearchParams(location.search);

    const params = queryToObject(query);

    params.per_page = String(pageSize);

    history.replace({
      pathname: location.pathname,
      search: objectToQuery(params, { addQueryPrefix: false }),
    });
  };

  return {
    handlePageChange,
    handlePageSizeChange,
    loading,
    info,
    data,
    loadData,
  };
}

export default usePagination;
